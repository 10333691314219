import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { RadioList, RequestInputs, TNRequestNames } from '@utils/request'
import { Direction, REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'
import requestForm from '@components/requestForms/request.form'
import MapForm from '../map/MapForm'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { useAppSelector } from '@services/store'
// import MaskedTextInput from './inputs/textWithValidation.inputs'
import { AddressInfo } from '../map/mapForm.interfaces'
import {
	AddressFieldNames,
	AddressInfoSectionProps,
	classes
} from './__index.utils'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '../addressForm/__index.utils'
import Notification from '../notification'
import { Collapse } from 'react-collapse'

const AddressInfoSection: FC<AddressInfoSectionProps> = ({
	form,
	label,
	errors,
	inputs,
	isConcernedInfosRequired,
	subStep,
	requiredFields,
	addressOptions,
	displayAddressDetails = true,
	showButtonUserLocation = true,
	onFixError,
	onSetInputs,
	onGetCadastralAndMatriculeNumber
}) => {
	const { authUser } = useContext<AppContextProps>(AppStateContext)

	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [addressOptionInput, setaddressOptionInput] = useState<RequestInputs>({
		name: AddressFieldNames.addressOption,
		label: pageAssets?.request_form_addressInfoSection_addressOption_label,
		labelKey: 'request_form_addressInfoSection_addressOption_label',
		value:
			answers?.addressOption?.toString() ||
			REQUEST_CONFIGS_KEYS.researchType.address,
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const radioListAddressOption: RadioList[] = addressOptions ?? [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.matriculeNumber,
			key: 'request_form_addressInfoSection_addressOption_thirdOption_label'
		}
	]

	const [addressFieldInput, setAddressFieldInput] = useState<RequestInputs>({
		name: AddressFieldNames.addressField,
		label:
			pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
		labelKey: 'request_form_addressInfoSection_addressOption_firstOption_label',
		value: answers.addressField || '',
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const [addressLotNumberInput, setAddressLotNumberInput] =
		useState<RequestInputs>({
			name: AddressFieldNames.addressLotNumber,
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_secondOption_label',
			value: answers.addressLotNumber?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressAppartmentInput, setAddressAppartmentInput] =
		useState<RequestInputs>({
			name: AddressFieldNames.addressAppartment,
			label: pageAssets?.apartment_label,
			labelKey: 'apartment_label',
			value: answers.addressAppartment?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressRegistrationNumberInput, setAddressRegistrationNumberInput] =
		useState<RequestInputs>({
			name: AddressFieldNames.addressRegistrationNumber,
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_thirdOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_thirdOption_label',
			value: answers.addressRegistrationNumber || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [displayInfo, setDisplayInfo] = useState<boolean>(false)
	const [displayWarning, setDisplayWarning] = useState<boolean>(false)
	const [displayAddressInfo, setDisplayAddressInfo] = useState<boolean>(false)
	//------------------------------------------------------------
	//------------------------------------------------------------
	//------------------------------------------------------------
	const onsetaddressOptionInput = (value: string) => {
		//radio on set with conditional data being saved in the addressInput
		setaddressOptionInput({
			...addressOptionInput,
			value
		})

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressOptionInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressOptionInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressOptionInput))
	}, [addressOptionInput])

	//------------------------------------------------------------
	const onSetAddressFieldInput = (value: string) => {
		if (value === '') {
			setAddressLotNumberInput({ ...addressLotNumberInput, value })
			setAddressFieldInput({ ...addressFieldInput, value })
			setAddressRegistrationNumberInput({
				...addressRegistrationNumberInput,
				value
			})
			setDisplayInfo(false)
			return
		}

		switch (addressOptionInput.value) {
			case REQUEST_CONFIGS_KEYS.researchType.cadastralNumber:
				//set addressField
				setAddressLotNumberInput({ ...addressLotNumberInput, value })
				break

			case REQUEST_CONFIGS_KEYS.researchType.matriculeNumber:
				// set addressLotNumber
				// set addressField
				setAddressRegistrationNumberInput({
					...addressRegistrationNumberInput,
					value
				})
				break

			default:
				setAddressFieldInput({ ...addressFieldInput, value })
				break
		}
		onGetCadastralAndMatriculeNumber && onGetCadastralAndMatriculeNumber(value)
		setDisplayInfo(true)
		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							addressLotNumberInput.name,
							addressRegistrationNumberInput.name,
							addressFieldInput.name
						].includes(error)
				)
			)
	}

	const onSelectAddressFieldInput = (value: string) => {
		setAddressFieldInput({ ...addressFieldInput, value })
		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressFieldInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressFieldInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressFieldInput))
	}, [addressFieldInput])

	//------------------------------------------------------------
	const onSetaddressLotNumberInput = (value: string) => {
		setAddressLotNumberInput({ ...addressLotNumberInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressLotNumberInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressLotNumberInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressLotNumberInput))
	}, [addressLotNumberInput])

	//------------------------------------------------------------
	const onSetaddressAppartmentInput = (value: string) => {
		setAddressAppartmentInput({ ...addressAppartmentInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressAppartmentInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressAppartmentInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressAppartmentInput))
	}, [addressAppartmentInput])

	//------------------------------------------------------------
	const onSetaddressRegistrationNumberInput = (value: string) => {
		setAddressRegistrationNumberInput({
			...addressRegistrationNumberInput,
			value
		})

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(
					errors!,
					addressRegistrationNumberInput.name
				)
			)
	}

	useEffect(() => {
		isFieldRequired(addressRegistrationNumberInput.name) &&
			onSetInputs &&
			onSetInputs(
				UpdateRequestInputsArray(inputs!, addressRegistrationNumberInput)
			)
	}, [addressRegistrationNumberInput])

	//------------------------------------------------------------

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
		} else {
			setConcernedInputsRequired(false)
		}

		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							addressAppartmentInput.name,
							addressRegistrationNumberInput.name,
							addressOptionInput.name,
							addressFieldInput.name,
							addressLotNumberInput.name
						].includes(error)
				)
			)
	}, [isConcernedInfosRequired])

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
		} else {
			setConcernedInputsRequired(false)
		}
	}, [])

	const setConcernedInputsRequired = (value: boolean) => {
		if (isFieldRequired(addressRegistrationNumberInput.name)) {
			setAddressRegistrationNumberInput({
				...addressRegistrationNumberInput,
				required: value
			})
		}
		if (isFieldRequired(addressFieldInput.name)) {
			setAddressFieldInput({
				...addressFieldInput,
				required: value
			})
		}
		if (isFieldRequired(addressLotNumberInput.name)) {
			setAddressLotNumberInput({
				...addressLotNumberInput,
				required: value
			})
		}
	}

	const getPlaceholder = () => {
		switch (addressOptionInput.value) {
			case REQUEST_CONFIGS_KEYS.researchType.cadastralNumber:
				return '0000000'

			case REQUEST_CONFIGS_KEYS.researchType.matriculeNumber:
				return '0000-00-0000-0-000-0000'

			default:
				return ''
		}
	}

	const getAddressFreatures = (data) => {
		if (data?.length == 0) {
			onSetAddressFieldInput('')
			setDisplayWarning(true)
			setDisplayAddressInfo(true)
		} else {
			setDisplayWarning(false)
			setDisplayAddressInfo(false)
		}
	}

	const renderMapForm = () => {
		return (
			<>
				<MapForm
					value={answers?.addressField}
					addressRequired={addressFieldInput.required}
					hasError={errors?.includes(addressFieldInput.name)}
					showButtonUserLocation={showButtonUserLocation}
					onAddressInputChange={onSetAddressFieldInput}
					onAddressChange={onAddressChange}
					addressPlaceholder={getPlaceholder()}
					searchByCadastralNumber={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
					}
					searchByRegistrationNumber={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.matriculeNumber
					}
					searchTypeForm={addressOptionInput.value}
					addressLabelNoWrapper
					getAddressFreatures={getAddressFreatures}
				/>
			</>
		)
	}

	const onAddressChange = (address: AddressInfo) => {
		getAddressByCoordinates(address.coordinates).then((res) => {
			const data = res?.data?.features?.[0].properties
			setAddressFieldInput({
				...addressFieldInput,
				value: data.address
			})

			setAddressRegistrationNumberInput({
				...addressRegistrationNumberInput,
				value: data.matricule
			})

			setAddressLotNumberInput({
				...addressLotNumberInput,
				value: data.cadastre
			})

			// if(!data.matricule && !data.cadastre) {
			// 	setDisplayWarning(true)
			// }else{
			// 	setDisplayWarning(false)
			// }
		})
	}

	const isFieldRequired = (fieldName) => {
		if (requiredFields) {
			return requiredFields.includes(fieldName)
		}

		return true
	}
	useEffect(() => {
		if (
			addressFieldInput.value != '' &&
			addressLotNumberInput.value != '' &&
			addressRegistrationNumberInput.value != ''
		) {
			setDisplayAddressInfo(true)
		} else {
			setDisplayAddressInfo(false)
			setDisplayWarning(false)
		}
	}, [addressFieldInput, addressLotNumberInput, addressRegistrationNumberInput])

	const getLabel = (value: string) => {
		if (value == radioListAddressOption[1]?.value) {
			return radioListAddressOption[1].label.toLowerCase()
		} else if (value == radioListAddressOption[2]?.value) {
			return radioListAddressOption[2].label.toLowerCase()
		}
		return ''
	}

	return (
		<>
			<div className={classes.inputGroup}>
				<div className={classes.radioStyle}>
					<RadioInput
						name={addressOptionInput.name}
						label={
							label ??
							pageAssets?.request_form_addressInfoSection_addressOption_label
						}
						value={addressOptionInput.value}
						list={radioListAddressOption}
						direction={Direction.horizontal}
						hasError={errors!.includes(addressOptionInput.name)}
						onChange={onsetaddressOptionInput}
						ref={addressOptionInput.ref as RefObject<HTMLInputElement>}
					/>
				</div>
				{renderMapForm()}
			</div>

			{!displayAddressDetails && displayAddressInfo && (
				<span>
					{addressOptionInput.value == radioListAddressOption[0]?.value &&
					addressLotNumberInput.value
						? pageAssets?.label_cadastral_number +
						  ': ' +
						  addressLotNumberInput.value
						: ''}

					{addressOptionInput.value == radioListAddressOption[1]?.value &&
					addressFieldInput.value
						? pageAssets?.label_address + ': ' + addressFieldInput.value
						: ''}
				</span>
			)}

			{!displayAddressDetails && displayWarning && (
				<Notification
					type={'warning'}
					text={
						pageAssets?.request_form_addressInfoSection_the_value_entered_not_found
							.replace(
								'****',
								pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label
							)
							.split(',')[0]
					}
				/>
			)}

			<Collapse isOpened={displayAddressDetails && displayAddressInfo}>
				{(displayInfo || displayWarning) && (
					<Notification
						type={displayWarning ? 'warning' : 'info'}
						text={
							displayInfo
								? pageAssets?.request_form_addressInfoSection_please_validate_the_following_info
								: pageAssets?.request_form_addressInfoSection_the_value_entered_not_found.replace(
										'****',
										getLabel(addressOptionInput.value)
								  )
						}
					/>
				)}
				<div className={classes.container}>
					<div className={classes.fieldsContainer}>
						{isFieldRequired(addressFieldInput.name) && (
							<TextInput
								id={addressFieldInput.name}
								label={addressFieldInput.label}
								value={addressFieldInput.value}
								onChange={onSelectAddressFieldInput}
								hasError={errors?.includes(addressFieldInput.name)}
								required={addressFieldInput.required}
								ref={addressFieldInput.ref as RefObject<HTMLInputElement>}
							/>
						)}
						{isFieldRequired(addressAppartmentInput.name) && (
							<TextInput
								id={addressAppartmentInput.name}
								label={addressAppartmentInput.label}
								value={addressAppartmentInput.value}
								onChange={onSetaddressAppartmentInput}
								hasError={errors?.includes(addressAppartmentInput.name)}
								onSetError={onFixError}
								required={addressAppartmentInput.required}
								ref={addressAppartmentInput.ref as RefObject<HTMLInputElement>}
							/>
						)}
						{isFieldRequired(addressLotNumberInput.name) && (
							<TextInput
								id={addressLotNumberInput.name}
								label={addressLotNumberInput.label}
								value={addressLotNumberInput.value}
								onChange={onSetaddressLotNumberInput}
								hasError={errors?.includes(addressLotNumberInput.name)}
								required={addressLotNumberInput.required}
								ref={addressLotNumberInput.ref as RefObject<HTMLInputElement>}
							/>
						)}

						{isFieldRequired(addressRegistrationNumberInput.name) && (
							<TextInput
								id={addressRegistrationNumberInput.name}
								label={addressRegistrationNumberInput.label}
								value={addressRegistrationNumberInput.value}
								onChange={onSetaddressRegistrationNumberInput}
								hasError={errors?.includes(addressRegistrationNumberInput.name)}
								onSetError={onFixError}
								required={addressRegistrationNumberInput.required}
								ref={
									addressRegistrationNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>
						)}
					</div>
				</div>
			</Collapse>
		</>
	)
}

export default AddressInfoSection
