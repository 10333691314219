import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList,
	PlantedTree,
	RadioList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	Direction,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	RequestCategoryEnum,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import SelectInput from './inputs/select.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'

import { Icon } from '@components/ui/Icon'
import { closeIcon, pdfIcon } from '@images/icons'
import Modal from '@components/ui/modal'
import RadioInput from './inputs/radio.input'
import Notification from '@components/ui/notification'
import DateFilterInput from './inputs/datefilter.input'
import Error from './inputs/error.input'

const TreePlantingForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	setDisableNextButton
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		rowSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		rowNumber: string
		rowNumberContainer: string
		ligneContainer: string
		rowRemoveContainer: string
		closeIcon: string
		withoutLabel: string
		tableSummary: string
		fileLink: string
		description: string
		modalContent: string
		error: string
		headers: string
		errorColor: string
		textDescription: string
		bottomMargin: string
		mobileInfoNotifDisplay: string
		webInfoNotifDisplay: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		rowSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '3%20%10%10%14%14%17%3%',
			columnGap: '1%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '47.5%47.5%',
				marginBottom: '15px'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		rowNumberContainer: {
			display: 'flex',
			'& span': {
				marginBottom: '9px'
			},

			[Breakpoints.maxWidth('sm')]: {
				'&:after': {
					background: '#ccc',
					height: '1px',
					content: '""',
					position: 'absolute',
					right: '35px',
					width: 'calc(100% - 92px)',
					marginTop: '12px'
				}
			}
		},
		rowRemoveContainer: {
			display: 'flex',
			'& > span': {
				marginBottom: '9px'
			}
		},

		rowNumber: {
			fontWeight: '500',
			color: Colors.white,
			borderRadius: '50%',
			fontSize: '14px',
			width: '26px',
			height: '26px',
			lineHeight: '26px',
			background: Colors.lightBlue,
			textAlign: 'center',
			display: 'block'
		},
		ligneContainer: {
			display: 'none',
			[Breakpoints.maxWidth('sm')]: {
				display: 'flex'
			}
		},
		closeIcon: {
			display: 'inline-flex',
			height: '30px',
			marginLeft: '-3px',
			cursor: 'pointer',
			alignSelf: 'flex-start',
			marginBottom: '7px',
			img: {
				height: '100%'
			},

			'& span': {
				display: 'none'
			},

			[Breakpoints.maxWidth('sm')]: {
				marginTop: '5px',
				height: '27px',
				'& span': {
					display: 'inline',
					color: '#0054a6',
					textDecoration: 'underline',
					lineHeight: '27px'
				}
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		withoutLabel: {
			'& label': {
				display: 'none',
				[Breakpoints.maxWidth('sm')]: {
					display: 'block'
				}
			}
		},
		tableSummary: {
			borderCollapse: 'collapse',
			width: '100%',
			'& td, & th': {
				fontSize: '16px',
				border: '1px solid #ccc',
				padding: '7px 15px',
				lineHeight: '28px'
			},
			'& tr:nth-child(even)': {
				backgroundColor: Colors.lightGrey4
			}
		},
		fileLink: {
			display: 'flex',
			'& a': {
				color: Colors.secondary,
				display: 'flex',
				cursor: 'pointer',
				textDecoration: 'none',

				'& img': {
					margin: '0 10px 0px 0',
					width: '20px',
					height: '20px'
				},

				'&:hover': {
					textDecoration: 'underline'
				}
			}
		},
		description: {
			marginBottom: '40px !important',
			'& a': {
				color: Colors.secondary,

				cursor: 'pointer',
				textDecoration: 'none',

				'&:hover': {
					textDecoration: 'underline'
				}
			}
		},
		errorColor: {
			color: `${Colors.errorRed}!important`
		},
		error: {
			'& strong': {
				color: `${Colors.errorRed}!important`
			},
			'& span': {
				color: `${Colors.errorRed}!important`
			}
		},
		headers: {
			'& strong': {
				'&::after': {
					content: '"*"',
					color: Colors.ligthPurple,
					fontSize: '1.2em',
					paddingLeft: '5px'
				}
			}
		},
		textDescription: {
			clear: 'both',
			display: 'block',
			margin: '5px 0px 3px',
			padding: '0',
			fontSize: '0.85em',
			lineHeight: '1.25em',
			color: Colors.lightGrey2,
			fontWeight: '400'
		},
		bottomMargin: {
			marginBottom: '-25px'
		},
		mobileInfoNotifDisplay: {
			display: 'none',
			[Breakpoints.maxWidth('sm')]: {
				display: 'block',
				gridColumnStart: 1,
				gridColumnEnd: 3,
				div: {
					marginBottom: '10px'
				}
			}
		},
		webInfoNotifDisplay: {
			[Breakpoints.maxWidth('sm')]: {
				display: 'none'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)

	const location = useAppSelector((state) => state.request.location)

	const [diameterError, setDiameterError] = useState<boolean>(false)
	const [heightError, setHeightError] = useState<boolean>(false)
	const [isMobile, setIsMobile] = useState(false)
	const [diameterDescription, setDiameterDescription] = useState<boolean>(false)
	const [heightDescription, setHeightDescription] = useState<boolean>(false)

	const [plantedTreeList, setPlantedTreeList] = useState<PlantedTree[]>(
		answers?.plantedTreeList
			? answers.plantedTreeList
			: [
					{
						species: '',
						diameter: '',
						cost: '',
						location: '',
						height: '',
						type: '',
						planting_date: '',
						openModal: false
					}
			  ]
	)

	// a fake RequestInputs to put plantedTreeList data in
	// value will be encoded as JSON
	const [plantedTreeListInput, setPlantedTreeListInput] =
		useState<RequestInputs>({
			name: 'plantedTreeList',
			label: pageAssets?.request_form_treePlanting_planting_information_title,
			labelKey: 'request_form_treePlanting_planting_information_title',
			value: JSON.stringify(plantedTreeList),
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const plantedTreeLocationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_plantedTreeLocation_frontYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.frontYard,
			key: 'select_plantedTreeLocation_frontYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_backYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.backYard,
			key: 'select_plantedTreeLocation_backYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_sideYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.sideYard,
			key: 'select_plantedTreeLocation_sideYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_shoreEdge,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.shoreEdge,
			key: 'select_plantedTreeLocation_shoreEdge'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_vacantLand,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.vacantLand,
			key: 'select_plantedTreeLocation_vacantLand'
		}
	]

	// ----------------------------------------------------------------
	// building address information

	const [
		buildingAddressSameHasApplicantInput,
		setBuildingAddressSameHasApplicantInput
	] = useState<RequestInputs>({
		name: 'buildingAddressSameHasApplicant',
		value: 'true',
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			value: location.address,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingPostalCodeInput, setBuildingPostalCodeInput] =
		useState<RequestInputs>({
			name: 'buildingPostalCode',
			value: location.postalCode,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingCityInput, setBuildingCityInput] = useState<RequestInputs>({
		name: 'buildingCity',
		value: location.city,
		label: '',
		labelKey: '',
		required: false
	})

	// ----------------------------------------------------------------

	// Preuve d'achat
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_treePlanting_firstInput_label,
		labelKey: 'request_form_treePlanting_firstInput_label',
		description: pageAssets?.request_form_treePlanting_firstInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.proofOfPurchase
	})

	// Photo(s) de ou des arbres plantés
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label: pageAssets?.request_form_treePlanting_secondInput_label,
		labelKey: 'request_form_treePlanting_secondInput_label',
		description: pageAssets?.request_form_treePlanting_secondInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.photographicEvidence
	})

	useEffect(() => {
		if (
			location.coordinates.latitude ==
				authUser?.profile?.address.coordinates?.latitude &&
			location.coordinates.longitude ==
				authUser?.profile?.address.coordinates?.longitude
		) {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'true'
			})
		} else {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'false'
			})
		}
	}, [location.coordinates])

	useEffect(() => {
		setBuildingAddressInput((prevState) => ({
			...prevState,
			value: location.address
		}))
	}, [location.address])

	useEffect(() => {
		setBuildingCityInput((prevState) => ({
			...prevState,
			value: location.city
		}))
	}, [location.city])

	useEffect(() => {
		setBuildingPostalCodeInput((prevState) => ({
			...prevState,
			value: location.postalCode
		}))
	}, [location.postalCode])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingAddressSameHasApplicantInput)
		)
	}, [buildingAddressSameHasApplicantInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingAddressInput))
	}, [buildingAddressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingPostalCodeInput))
	}, [buildingPostalCodeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingCityInput))
	}, [buildingCityInput])

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		setSecondQuestion({
			...secondQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------

		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}

		// secondInput input
		if (!!answers.secondInput && secondQuestion.files.size == 0) {
			getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
				setSecondQuestion({
					...secondQuestion,
					files: result
				})
			})
		}
	}, [])
	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	/////////////////////////////////////////////////////////////////
	////////////////// ON CHANGE FUNCTIONS //////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, plantedTreeListInput))
	}, [plantedTreeListInput])

	const onSetPlantedtreeCurrentInput = (
		value: string | boolean,
		currentInput
	) => {
		const { row, attribute } = currentInput
		let itemList = JSON.parse(JSON.stringify(plantedTreeList))
		const isCostLimit = attribute === 'cost' && Number(value) > 99999
		const isCostZero = attribute === 'cost' && value === '0'
		const isDiameterLimit =
			attribute === 'diameter' && Number(value) > 999999999

		if (value !== '' && Number(value) < 0) {
			return
		}

		if (isCostLimit || isCostZero) return
		if (isDiameterLimit) return

		itemList[row][`${attribute}`] = value

		setPlantedTreeList(itemList)

		setPlantedTreeListInput({
			...plantedTreeListInput,
			value: JSON.stringify(itemList)
		})

		if (attribute == 'height' || attribute == 'diameter') {
			itemList[row][`type`] = ''
		}

		onFixError(updatePlantedTreeErrorsArray(itemList))
	}

	const addPlantedTreeRow = () => {
		let itemList = JSON.parse(JSON.stringify(plantedTreeList))

		let newItem: PlantedTree = {
			species: '',
			diameter: '',
			cost: '',
			location: '',
			height: '',
			planting_date: '',
			type: '',
			openModal: false
		}

		itemList.push(newItem)

		setPlantedTreeList(itemList)

		setPlantedTreeListInput({
			...plantedTreeListInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updatePlantedTreeErrorsArray(itemList))
	}

	const removePlantedTreeRow = (row: number) => {
		let itemList = [...plantedTreeList]

		itemList.splice(row, 1)

		setPlantedTreeList(itemList)

		setPlantedTreeListInput({
			...plantedTreeListInput,
			value: JSON.stringify(itemList)
		})

		let itemListLength = itemList.length

		onFixError(
			updatePlantedTreeRowErrorsArray([
				`plantedtreeSpecies${row}`,
				`plantedtreeDiameter${row}`,
				`plantedtreeHeight${row}`,
				`plantedtreeCost${row}`,
				`plantedtreeLocation${row}`,
				`plantedtreePlanting_date${row}`,

				// suppr last row errors
				`plantedtreeSpecies${itemListLength}`,
				`plantedtreeDiameter${itemListLength}`,
				`plantedtreeHeight${itemListLength}`,
				`plantedtreeCost${itemListLength}`,
				`plantedtreeLocation${itemListLength}`,
				`plantedtreePlanting_date${itemListLength}`
			])
		)
	}

	const updatePlantedTreeRowErrorsArray = (
		inputNameList: string[]
	): string[] => {
		return errors.filter((err) => !inputNameList.includes(err))
	}

	// @Todo Fct to refactor
	const updatePlantedTreeErrorsArray = (itemList: PlantedTree[]): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('plantedtree')) {
				itemList.forEach((plantedTree, row) => {
					if (
						plantedTree.species == '' &&
						error == `plantedtreeSpecies${row}`
					) {
						errorArray.push(error)
					}
					if (
						plantedTree.diameter == '' &&
						error == `plantedtreeDiameter${row}`
					) {
						errorArray.push(error)
					}
					if (plantedTree.height == '' && error == `plantedtreeHeight${row}`) {
						errorArray.push(error)
					}
					if (plantedTree.cost == '' && error == `plantedtreeCost${row}`) {
						errorArray.push(error)
					}
					if (
						plantedTree.location == '' &&
						error == `plantedtreeLocation${row}`
					) {
						errorArray.push(error)
					}
					if (
						plantedTree.planting_date == '' &&
						error == `plantedtreePlanting_date${row}`
					) {
						errorArray.push(error)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	const radioTreeType: RadioList[] = [
		{
			label: pageAssets?.label_leafy,
			value: '00000',
			key: 'label_leafy'
		},
		{
			label: pageAssets?.label_coniferous,
			value: '11111',
			key: 'label_coniferous'
		}
	]

	useEffect(() => {
		let hasError: boolean = false
		let displayHeightDesc: boolean = false
		let displayDiameterDesc: boolean = false

		let isHeightZero: boolean = false
		let isDiameterZero: boolean = false

		plantedTreeList?.forEach((plantedTree) => {
			const height =
				plantedTree.height != '' ? Number(plantedTree.height) : null
			const diameter =
				plantedTree.diameter != '' ? Number(plantedTree.diameter) : null

			if (
				height != null &&
				height < 150 &&
				diameter != null &&
				plantedTree.type == ''
			) {
				displayHeightDesc = true
			}

			if (
				diameter != null &&
				diameter < 3 &&
				height != null &&
				plantedTree.type == ''
			) {
				displayDiameterDesc = true
			}

			if (
				height != null &&
				diameter != null &&
				currentIndexHasError(height, diameter, plantedTree.type)
			) {
				hasError = true
			}

			if (height != null && height == 0) {
				isHeightZero = true
			}
			if (diameter != null && diameter == 0) {
				isDiameterZero = true
			}
		})
		setDisableNextButton &&
			setDisableNextButton(!(hasError || isDiameterZero || isHeightZero))
		setHeightError(hasError || isHeightZero || displayHeightDesc)
		setDiameterError(hasError || isDiameterZero || displayDiameterDesc)

		// // Collapse the height and diameter descriptions
		setHeightDescription(displayHeightDesc || hasError)
		setDiameterDescription(displayDiameterDesc || hasError)
	}, [plantedTreeList])

	const errorsHasName = (name: string) => {
		return !!errors.find((err) => err.includes(name))
	}

	const currentIndexHasError = (
		height: number,
		diameter: number,
		treeType?: string
	) => {
		// This function will verifies the conditions that meet the requirements for feuillu and conifère tree
		return (
			(height >= 150 &&
				height < 200 &&
				diameter <= 3 &&
				treeType != radioTreeType[1].value &&
				treeType != '') ||
			(height < 150 &&
				diameter > 3 &&
				treeType != radioTreeType[0].value &&
				treeType != '') ||
			(height < 150 && diameter <= 3)
		)
	}

	const handleFocus = (row: number) => {
		const height = Number(plantedTreeList[row].height)
		const diameter = Number(plantedTreeList[row].diameter)
		const type = plantedTreeList[row].type

		if (
			plantedTreeList[row].diameter.trim() != '' &&
			plantedTreeList[row].height.trim() != ''
		) {
			if (
				(height >= 150 &&
					height < 200 &&
					diameter <= 3 &&
					type != radioTreeType[1].value) ||
				(diameter > 3 && height < 150 && type != radioTreeType[0].value)
			) {
				onSetPlantedtreeCurrentInput(true, { row, attribute: 'openModal' })
			} else {
				onSetPlantedtreeCurrentInput(false, { row, attribute: 'openModal' })
			}
		}
	}

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768) // Is Mobile
		}
		// Initial check
		handleResize()
		// Event listener for screen resize
		window.addEventListener('resize', handleResize)

		// Cleanup the event listener
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const displayErrorMessageNotification = (
		row: number,
		heightHasError: boolean,
		diameterHasError: boolean,
		mobileDisplay: boolean
	) => {
		let infoTextList: string[] = []

		if (heightHasError) {
			infoTextList.push(
				pageAssets?.request_form_treePlanting_plantedtreeDiameter_label_description
			)
		}
		if (diameterHasError) {
			infoTextList.push(
				pageAssets?.request_form_treePlanting_plantedtreeHeight_label_description
			)
		}
		let rowHasErrors
		if (errors) {
			rowHasErrors = errors.filter((error) =>
				[
					`plantedtreeSpecies${row}`,
					`plantedtreeDiameter${row}`,
					`plantedtreeHeight${row}`,
					`plantedtreeCost${row}`,
					`plantedtreeLocation${row}`,
					`plantedtreePlanting_date${row}`
				].includes(error)
			)
		}
		if (rowHasErrors.length > 0) {
			infoTextList.push('Veuillez remplir les champs obligatoires')
		}

		if (heightHasError || diameterHasError || rowHasErrors.length > 0) {
			return (
				<div
					className={
						mobileDisplay
							? classes.mobileInfoNotifDisplay
							: classes.webInfoNotifDisplay
					}
				>
					<Notification
						text={infoTextList.join('<br /><br />')}
						type="error"
						hasHtml
					/>
				</div>
			)
		} else {
			return (
				<div
					className={
						mobileDisplay
							? classes.mobileInfoNotifDisplay
							: classes.webInfoNotifDisplay
					}
				></div>
			)
		}
	}

	///////////////////// RENDERING ROW FUNCTION ///////////////////////
	const renderPlantedTreeRow = (plantedtree: PlantedTree, row: number) => {
		let hasError = false
		const height = plantedtree.height != '' ? Number(plantedtree.height) : null
		const diameter =
			plantedtree.diameter != '' ? Number(plantedtree.diameter) : null
		let heightHasError: boolean = false
		let diameterHasError: boolean = false

		if (height != null && diameter != null) {
			hasError = currentIndexHasError(height, diameter, plantedtree.type)
		}

		if (
			height != null &&
			height < 150 &&
			diameter != null &&
			plantedtree.type == ''
		) {
			heightHasError = true
		}

		if (
			diameter != null &&
			diameter < 3 &&
			height != null &&
			height < 200 &&
			plantedtree.type == '' &&
			hasError
		) {
			diameterHasError = true
		}

		const isDiameterZero = diameter != null && diameter == 0
		const isHeightZero = height != null && height == 0

		return (
			<>
				<div className={classes.rowSection}>
					<div
						className={
							subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
								? classes.rowNumberContainer
								: ''
						}
					>
						<span className={classes.rowNumber}>{row + 1}</span>
					</div>

					<div className={classes.ligneContainer}> </div>

					{displayErrorMessageNotification(
						row,
						heightHasError,
						diameterHasError,
						true
					)}

					<TextInput
						id={`plantedtreeSpecies${row}`}
						label={
							pageAssets?.request_form_treePlanting_plantedtreeSpecie_label
						}
						value={plantedtree.species}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, { row, attribute: 'species' })
						}
						hasError={errors.includes(`plantedtreeSpecies${row}`)}
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						onFocus={() => handleFocus(row)}
						required
						titleAndDescError={
							!isMobile && row === 0 && errorsHasName('plantedtreeSpecie')
						}
					/>

					<TextInput
						id={`plantedtreeDiameter${row}`}
						label={
							pageAssets?.request_form_treePlanting_plantedtreeDiameter_label
						}
						// description={
						// 	((diameterDescription && !isMobile) ||
						// 		(isMobile && diameterHasError)) &&
						// 	hasError
						// 		? pageAssets?.request_form_treePlanting_plantedtreeDiameter_label_description
						// 		: undefined
						// }
						value={plantedtree.diameter}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, { row, attribute: 'diameter' })
						}
						hasError={errors.includes(`plantedtreeDiameter${row}`)}
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						type="number"
						hasCustomErrorWithoutText={hasError || isDiameterZero}
						customClassName="diameterHasError"
						titleAndDescError={
							!isMobile && row === 0 && errorsHasName('plantedtreeDiameter')
						}
						onKeyPress={(event) => {
							const charCode = event.which ? event.which : event.keyCode
							const char = String.fromCharCode(charCode)
							const value = event.target.value + char

							if (
								!/^\d*\.?\d*$/.test(value) ||
								(char === '.' && value.split('.').length > 2)
							) {
								event.preventDefault()
							}
						}}
					/>

					<TextInput
						id={`plantedtreeHeight${row}`}
						label={
							pageAssets?.request_form_treePlanting_plantedtreeHeight_label
						}
						// description={
						// 	((heightDescription && !isMobile) ||
						// 		(isMobile && heightHasError)) &&
						// 	hasError
						// 		? pageAssets?.request_form_treePlanting_plantedtreeHeight_label_description
						// 		: undefined
						// }
						value={plantedtree.height}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, { row, attribute: 'height' })
						}
						hasError={errors.includes(`plantedtreeHeight${row}`)}
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						type="number"
						hasCustomErrorWithoutText={hasError || isHeightZero}
						customClassName="heightHasError"
						titleAndDescError={
							!isMobile && row === 0 && errorsHasName('plantedtreeHeight')
						}
						onKeyPress={(event) => {
							const charCode = event.which ? event.which : event.keyCode
							const char = String.fromCharCode(charCode)
							const value = event.target.value + char

							if (
								!/^\d*\.?\d*$/.test(value) ||
								(char === '.' && value.split('.').length > 2)
							) {
								event.preventDefault()
							}
						}}
					/>

					<TextInput
						id={`plantedtreeCost${row}`}
						label={pageAssets?.request_form_treePlanting_plantedtreeCost_label}
						value={plantedtree.cost}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, { row, attribute: 'cost' })
						}
						hasError={errors.includes(`plantedtreeCost${row}`)}
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						type="number"
						isMoneyInput
						onFocus={() => handleFocus(row)}
						required
						titleAndDescError={
							!isMobile && row === 0 && errorsHasName('plantedtreeCost')
						}
						onKeyPress={(event) => {
							const charCode = event.which ? event.which : event.keyCode
							const char = String.fromCharCode(charCode)
							const value = event.target.value + char

							if (
								!/^\d*\.?\d*$/.test(value) ||
								(char === '.' && value.split('.').length > 2)
							) {
								event.preventDefault()
							}
						}}
					/>

					<SelectInput
						id={`plantedtreeLocation${row}`}
						label={
							pageAssets?.request_form_treePlanting_plantedtreeLocation_label
						}
						value={plantedtree.location}
						list={plantedTreeLocationList}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, { row, attribute: 'location' })
						}
						hasError={errors.includes(`plantedtreeLocation${row}`)}
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						onFocus={() => handleFocus(row)}
						required
						titleAndDescError={
							!isMobile && row === 0 && errorsHasName('plantedtreeLocation')
						}
					/>
					<DateFilterInput
						id={`plantedtreePlanting_date${row}`}
						label={pageAssets?.request_form_treePlanting_plantaion_date}
						value={plantedtree.planting_date}
						onChange={(e) =>
							onSetPlantedtreeCurrentInput(e, {
								row,
								attribute: 'planting_date'
							})
						}
						hasError={errors.includes(`plantedtreePlanting_date${row}`)}
						dateFormat="dd/MM/yyyy"
						classWrapper={row > 0 ? classes.withoutLabel : ''}
						onFocus={() => handleFocus(row)}
						required
						titleAndDescError={
							!isMobile &&
							row === 0 &&
							errorsHasName('plantedtreePlanting_date')
						}
						maxDate={new Date()}
					/>

					<div className={classes.rowRemoveContainer}>
						{plantedTreeList.length > 1 && (
							<span
								className={classes.closeIcon}
								onClick={() => removePlantedTreeRow(row)}
							>
								<Icon src={closeIcon} />
								<span>{pageAssets?.request_form_treePlanting_remove_tree}</span>
							</span>
						)}
					</div>
				</div>

				{displayErrorMessageNotification(
					row,
					heightHasError,
					diameterHasError,
					false
				)}

				<Modal
					isOpen={Boolean(plantedtree?.openModal) ?? false}
					showExitBtn
					title={pageAssets?.label_tree_height}
					cancelButtonText={pageAssets?.close_label}
					primaryButtonText={pageAssets?.button_continue}
					onCancelBtn={() => {
						onSetPlantedtreeCurrentInput(false, {
							row,
							attribute: 'openModal'
						})
					}}
					onPrimaryButton={() => {
						onSetPlantedtreeCurrentInput(false, {
							row,
							attribute: 'openModal'
						})
					}}
					onSetIsOpen={() => {}}
					onCloseModal={() => {
						onSetPlantedtreeCurrentInput(false, {
							row,
							attribute: 'openModal'
						})
					}}
					disabled={hasError}
				>
					<div className={classes.modalContent}>
						<Text
							content={
								pageAssets?.request_form_treePlanting_plantedtreeHeight_label_description
							}
						/>
						<div style={{ marginTop: '20px' }}></div>
						<RadioInput
							name={`treePlanting_tree_type${row}`}
							label={
								pageAssets?.request_form_treePlanting_is_your_tree_a_deciduous_tree
							}
							value={plantedtree?.type ?? ''}
							list={radioTreeType}
							direction={Direction.vertical}
							hasError={errors!.includes(`treePlanting_tree_type${row}`)}
							onChange={(e) =>
								onSetPlantedtreeCurrentInput(e, {
									row,
									attribute: 'type'
								})
							}
							hasBackgroundWrapper={true}
						/>
						<Collapse isOpened={!!plantedtree.type && hasError}>
							<Notification
								type="warning"
								text={
									pageAssets?.request_form_treePlanting_infor_diameter_or_height_is_not_enough
								}
								showPrefixIcon
							/>
						</Collapse>
					</div>
				</Modal>
			</>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_treePlanting_planting_information_title
								}
							</h4>
						</div>

						<div style={{ overflowX: 'auto' }}>
							<table className={classes.tableSummary}>
								<thead>
									<tr>
										<th></th>
										<th>
											<strong>
												{
													pageAssets?.request_form_treePlanting_plantedtreeSpecie_label
												}
											</strong>
										</th>
										<th>
											<strong>
												{
													pageAssets?.request_form_treePlanting_plantedtreeDiameter_label
												}
											</strong>
										</th>
										<th>
											<strong>
												{
													pageAssets?.request_form_treePlanting_plantedtreeHeight_label
												}
											</strong>
										</th>
										<th>
											<strong>
												{
													pageAssets?.request_form_treePlanting_plantedtreeCost_label
												}
											</strong>
										</th>
										<th>
											<strong>
												{
													pageAssets?.request_form_treePlanting_plantedtreeLocation_label
												}
											</strong>
										</th>
										<th>
											<strong>
												{pageAssets?.request_form_treePlanting_plantaion_date}
											</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									{answers.plantedTreeList?.map((plantedTree, index) => {
										return (
											<tr key={`plantedTree${index}`}>
												<td>
													<span className={classes.rowNumber}>{index + 1}</span>
												</td>
												<td>{plantedTree.species}</td>
												<td>{plantedTree.diameter}</td>
												<td>{plantedTree.height}</td>
												<td>{plantedTree.cost}</td>
												<td>
													{
														pageAssets[
															plantedTreeLocationList.find(
																(el) => el.value == plantedTree.location
															)?.key!
														]
													}
												</td>
												<td>
													{pageUtils.getLocaleDate(plantedTree.planting_date)}
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFiles
							subtitle={firstQuestion.label}
							name={firstQuestion.name}
							required={firstQuestion.required}
							files={firstQuestion.files}
							hasError={errors.includes(firstQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFirstQuestion}
							maxFiles={firstQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={secondQuestion.label}
							name={secondQuestion.name}
							required={secondQuestion.required}
							files={secondQuestion.files}
							hasError={errors.includes(secondQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSecondQuestion}
							maxFiles={secondQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				{subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
						<section>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_treePlanting_planting_information_title
								}
							</h4>

							<p
								className={joinClasses([classes.description, 'link-redirect'])}
							>
								<Text
									content={
										pageAssets?.request_form_treePlanting_planting_information_description
									}
								/>
							</p>

							{/* /////////////////  planted Tree List  //////////////// */}
							{plantedTreeList.map((plantedTree, index) => {
								return renderPlantedTreeRow(plantedTree, index)
							})}

							{/* /////////////////  Add Button  //////////////// */}
							{plantedTreeList.length < 10 && (
								<a
									style={{ marginBottom: '12px' }}
									onClick={() => addPlantedTreeRow()}
									className={classes.button}
								>
									{pageAssets?.request_form_treePlanting_add_tree}
								</a>
							)}
						</section>
					)}
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				{subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
						<section className={pageUtils.classes.sectionNoPadding}>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>

							<RequestFiles
								subtitle={firstQuestion.label}
								name={firstQuestion.name}
								required
								files={firstQuestion.files}
								hasError={errors.includes(firstQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFirstQuestion}
								subSteps={subSteps}
								maxFiles={firstQuestion.numberMaxFiles}
								description={firstQuestion.description}
							></RequestFiles>

							<RequestFiles
								subtitle={secondQuestion.label}
								name={secondQuestion.name}
								required
								files={secondQuestion.files}
								hasError={errors.includes(secondQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSecondQuestion}
								subSteps={subSteps}
								maxFiles={secondQuestion.numberMaxFiles}
								description={secondQuestion.description}
							></RequestFiles>
						</section>
					)}
			</Collapse>
		</>
	)
}

export default TreePlantingForm
