import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories,
	Direction,
	REQUEST_CONFIGS_KEYS
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import RadioInput from '@components/requestForms/inputs/radio.input'
import TextAreaInput from './inputs/textarea.inputs'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import { closeIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import RequiredIcon from '@components/ui/requiredIcon'
import MapForm from '@components/ui/map/MapForm'
import Modal from '@components/ui/modal'
import { Link } from 'gatsby'
import Notification from '@components/ui/notification'

const BuildingTransactionRequestForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		fileContainer: string
		fileHelperSection: string
		button: string
		buttonContainer: string
		inputContainer: string
		dimensionContainer: string
		radioWrapper: string
		rowSection: string
		rowNumber: string
		rowNumberContainer: string
		rowRemoveContainer: string
		withoutLabel: string
		closeIcon: string
		labelMargin: string
		errorLabel: string
		modalLinkContainer: string
		modalContent: string
		requestObjectWrapper: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		inputContainer: {
			marginBottom: '20px',
			display: 'block',
			width: '-webkit-fill-available'
		},
		dimensionContainer: {
			margin: '-20px 0 20px',
			display: 'block',
			width: '-webkit-fill-available'
		},
		radioWrapper: {
			margin: '10px'
		},
		rowSection: {
			display: 'flex',
			alignItems: 'end',
			columnGap: '1%',
			'& > div': {
				marginBottom: '20px'
			}
		},
		rowRemoveContainer: {
			display: 'flex',
			'& > span': {
				marginBottom: '9px'
			}
		},
		closeIcon: {
			display: 'inline-flex',
			height: '30px',
			marginLeft: '-3px',
			cursor: 'pointer',
			alignSelf: 'flex-start',
			marginBottom: '7px',
			img: {
				height: '100%'
			},

			'& span': {
				display: 'none'
			},

			[Breakpoints.maxWidth('sm')]: {
				marginTop: '5px',
				height: '27px',
				'& span': {
					display: 'inline',
					color: '#0054a6',
					textDecoration: 'underline',
					lineHeight: '27px'
				}
			}
		},
		labelMargin: {
			marginBottom: '20px'
		},
		errorLabel: {
			color: Colors.errorRed,
			'& span': {
				color: Colors.errorRed + ' !important'
			}
		},
		modalLinkContainer: {
			display: 'block',
			margin: '-10px 0 20px'
		},
		modalContent: {
			color: Colors.black2,
			fontSize: '16px',
			lineHeight: '26px',
			textAlign: 'left',
			'& #viewDiv': {
				height: '490px'
			}
		},
		requestObjectWrapper: {
			'word-wrap': 'break-word',
			[Breakpoints.minWidth('md')]: {
				width: '45rem'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [frontOrDepthInputHasError, setFrontOrDepthInputHasError] =
		useState<Boolean>(false)

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------

	//****** lotRequestReason inputs *******

	const [lotRequestReasonInput, setLotRequestReasonInput] =
		useState<RequestInputs>({
			name: 'lotRequestReason',
			label:
				pageAssets?.request_form_buildingTransactionRequest_lotRequestReason_label,
			labelKey:
				'request_form_buildingTransactionRequest_lotRequestReason_label',
			value: answers.lotRequestReason?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [showTheClaimantInput, setShowTheClaimantInput] = useState<boolean>(
		lotRequestReasonInput.value == REQUEST_CONFIGS_KEYS.requestReason.sell
	)

	const radioListLotRequestReason: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_lotRequestReason_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.requestReason.purchase,
			key: 'request_form_buildingTransactionRequest_lotRequestReason_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_lotRequestReason_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.requestReason.sell,
			key: 'request_form_buildingTransactionRequest_lotRequestReason_secondOption_label'
		}
	]

	//****** isApplicantLotOwner inputs ******

	//
	const [isApplicantLotOwnerInput, setIsApplicantLotOwnerInput] =
		useState<RequestInputs>({
			name: 'isApplicantLotOwner',
			label:
				pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_label,
			labelKey:
				'request_form_buildingTransactionRequest_isApplicantLotOwner_label',
			value: answers.isApplicantLotOwner?.toString() || '',
			required: showTheClaimantInput,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [showTheClaimantBlock, setShowTheClaimantBlock] = useState<boolean>(
		isApplicantLotOwnerInput.value == 'false'
	)

	const radioListIsApplicantLotOwner: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_label_firstOption_label,
			value: 'true',
			key: 'request_form_buildingTransactionRequest_isApplicantLotOwner_label_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_label_secondOption_label,
			value: 'false',
			key: 'request_form_buildingTransactionRequest_isApplicantLotOwner_label_secondOption_label'
		}
	]

	const [relationToOwnerInput, setRelationToOwnerInput] =
		useState<RequestInputs>({
			name: 'relationToOwner',
			label:
				pageAssets?.request_form_buildingTransactionRequest_relationToOwnerInput_label,
			labelKey:
				'request_form_buildingTransactionRequest_relationToOwnerInput_label',
			value: answers.relationToOwner?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	////////////////////////////////////////////////////////////////

	//----------------------------------------------------------------
	//--------------- DESCRIPTION INPUTS ------------
	//----------------------------------------------------------------

	const [lotNumbersList, setLotNumbersList] = useState<string[]>(
		answers?.lotNumbersList ?? ['']
	)

	const [lotNumbersInput, setLotNumbersInput] = useState<RequestInputs>({
		name: 'lotNumbersList',
		label: pageAssets?.request_form_buildingTransactionRequest_lotNumbers_label,
		labelKey: 'request_form_buildingTransactionRequest_lotNumbers_label',
		value: JSON.stringify(lotNumbersList),
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		extraParams: {
			customErrorText: pageAssets?.label_tooShortErrorText
		}
	})

	const [registrationNumberInput, setRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'registrationNumber',
			label:
				pageAssets?.request_form_buildingTransactionRequest_registrationNumber_label,
			labelKey:
				'request_form_buildingTransactionRequest_registrationNumber_label',
			value: answers.registrationNumber?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [landAreaDimensionsInput, setLandAreaDimensionsInput] =
		useState<RequestInputs>({
			name: 'landAreaDimensions',
			label:
				pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_label,
			labelKey:
				'request_form_buildingTransactionRequest_landAreaDimensions_label',
			value: answers.landAreaDimensions?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [landAreaMeasurementsInput, setLandAreaMeasurementsInput] =
		useState<RequestInputs>({
			name: 'landAreaMeasurements',
			label: '',
			labelKey: '',
			value:
				answers.landAreaMeasurements?.toString() ||
				REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit.squaredMeters,
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const radioListLandAreaDimensions: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit.squaredMeters,
			key: 'request_form_buildingTransactionRequest_landAreaDimensions_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit.squaredFeet,
			key: 'request_form_buildingTransactionRequest_landAreaDimensions_secondOption_label'
		}
	]

	const [requestObjectInput, setRequestObjectInput] = useState<RequestInputs>({
		name: 'requestObject',
		label:
			pageAssets?.request_form_buildingTransactionRequest_requestObject_label,
		labelKey: 'request_form_buildingTransactionRequest_requestObject_label',
		value: answers.requestObject || '',
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [optionalFilesInput, setOptionalFilesInput] =
		useState<RequestInputFiles>({
			name: 'optionalFilesInput',
			label: pageAssets?.request_form_claimNotice_firstInput_label,
			labelKey: 'request_form_claimNotice_firstInput_label',
			description:
				pageAssets?.request_form_buildingTransactionRequest_optionalFilesInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'optionalFilesInput')?.files || []
			),
			required: false,
			numberMaxFiles: 10,
			category: requestCategories.other
		})

	const [locationCertificateInput, setLocationCertificateInput] =
		useState<RequestInputFiles>({
			name: 'locationCertificateInput',
			label:
				pageAssets?.request_form_buildingTransactionRequest_locationCertificateInput_label,
			labelKey:
				'request_form_buildingTransactionRequest_locationCertificateInput_label',
			description:
				pageAssets?.request_form_buildingTransactionRequest_locationCertificateInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'locationCertificateInput')
					?.files || []
			),
			required: false,
			numberMaxFiles: 10,
			category: requestCategories.other
		})

	const [isOpenModal, setIsOpenModal] = useState<string>('')

	// ------------- DETAILS INPUTS SETTERS ----------------

	const onSelectRequestReason = (value: string) => {
		setLotRequestReasonInput({ ...lotRequestReasonInput, value })

		setTimeout(() => {
			if (value === REQUEST_CONFIGS_KEYS.requestReason.sell) {
				setShowTheClaimantInput(true)
			} else {
				setShowTheClaimantInput(false)
			}

			setIsApplicantLotOwnerInput({
				...isApplicantLotOwnerInput,
				required: value === REQUEST_CONFIGS_KEYS.requestReason.sell
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![lotRequestReasonInput.name, isApplicantLotOwnerInput.name].includes(
						error
					)
			)
		)
	}

	const onSelectIsApplicantOwnerInput = (value: string) => {
		setIsApplicantLotOwnerInput({
			...isApplicantLotOwnerInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'false') {
				setShowTheClaimantBlock(true)
			} else {
				setShowTheClaimantBlock(false)
			}
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isApplicantLotOwnerInput.name)
		)
	}

	const onSelectRelationToOwnerInput = (value: string) => {
		setRelationToOwnerInput({ ...relationToOwnerInput, value })
	}

	const onSelectRegistrationNumberInput = (value: string) => {
		setRegistrationNumberInput({ ...registrationNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, registrationNumberInput.name)
		)
	}

	const onSelectLandAreaDimensionsInput = (value: string) => {
		if (value !== '' && Number(value) < 0) {
			return
		}

		setLandAreaDimensionsInput({ ...landAreaDimensionsInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, landAreaDimensionsInput.name)
		)
	}

	const onSelectLandAreaMeasurementInput = (value: string) => {
		setLandAreaMeasurementsInput({ ...landAreaMeasurementsInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, landAreaMeasurementsInput.name)
		)
	}

	const onSelectRequestObjectInput = (value: string) => {
		setRequestObjectInput({ ...requestObjectInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, requestObjectInput.name))
	}

	const onSelectOptionalFilesInput = (files: Set<File>) => {
		setOptionalFilesInput({
			...optionalFilesInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, optionalFilesInput.name))
	}

	const onSelectLocationCertificateInput = (files: Set<File>) => {
		setLocationCertificateInput({
			...locationCertificateInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, optionalFilesInput.name))
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, lotRequestReasonInput))
	}, [lotRequestReasonInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isApplicantLotOwnerInput))
	}, [isApplicantLotOwnerInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, relationToOwnerInput))
	}, [relationToOwnerInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, lotNumbersInput))
	}, [lotNumbersInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, registrationNumberInput))
	}, [registrationNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, landAreaDimensionsInput))
	}, [landAreaDimensionsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, landAreaMeasurementsInput))
	}, [landAreaMeasurementsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, requestObjectInput))
	}, [requestObjectInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, optionalFilesInput)
		)
	}, [optionalFilesInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, locationCertificateInput)
		)
	}, [locationCertificateInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		errors.includes('landFrontDimensions') ||
		errors.includes('landDepthDimensions')
			? setFrontOrDepthInputHasError(true)
			: setFrontOrDepthInputHasError(false)
	})

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const onSetLotNumbersCurrentInput = (value: string, currentInput) => {
		const row = currentInput.row ?? currentInput

		let itemList = [...lotNumbersList]

		if ((!Number(value) && value !== '') || value.length > 7) {
			return
		}

		itemList[row] = value

		setLotNumbersList(itemList)

		setLotNumbersInput({
			...lotNumbersInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updateLotNumberRowErrorsArray([`lotNumberValue${row}`]))
	}

	const addLotNumbersRow = () => {
		let itemList = [...lotNumbersList]

		let newItem = ''

		itemList.push(newItem)

		setLotNumbersList(itemList)

		setLotNumbersInput({
			...lotNumbersInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updateLotNumbersErrorsArray(itemList))
	}

	const removeLotNumberRow = (row: number) => {
		let itemList = [...lotNumbersList]

		itemList.splice(row, 1)

		setLotNumbersList(itemList)

		setLotNumbersInput({
			...lotNumbersInput,
			value: JSON.stringify(itemList)
		})

		let itemListLength = itemList.length

		onFixError(
			updateLotNumberRowErrorsArray([
				`lotNumberValue${row}`,
				// suppr last row errors
				`lotNumberValue${itemListLength}`
			])
		)
	}

	const updateLotNumberRowErrorsArray = (inputNameList: string[]): string[] => {
		return errors.filter((err) => !inputNameList.includes(err))
	}

	// @Todo Fct to refactor
	const updateLotNumbersErrorsArray = (itemList: string[]): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('lotNumbers')) {
				itemList.forEach((lotNumber, row) => {
					if (lotNumber == '' && error == `lotNumberValue${row}`) {
						errorArray.push(error)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	///////////////////// RENDERING ROW FUNCTION ///////////////////////

	const renderLotNumberRow = (lotNumber: string, row: number) => {
		return (
			<>
				{lotNumbersList.length > 0 && (
					<>
						<div className={classes.rowSection}>
							<TextInput
								id={`lotNumberId${row}`}
								label={lotNumbersInput.label + ` ${row + 1}`}
								value={lotNumber}
								onChange={(e) => onSetLotNumbersCurrentInput(e, { row })}
								required
								customErrorText={
									errors.includes(`lotNumberValue${row}`)
										? pageAssets.label_tooShortErrorText
										: pageAssets.required_text
								}
								hasError={errors.includes(`lotNumberValue${row}`)}
								ref={lotNumbersInput.ref as RefObject<HTMLInputElement>}
								classWrapper={classes.inputContainer}
							/>
							<div className={classes.rowRemoveContainer}>
								{lotNumbersList.length > 1 && (
									<span
										key={row}
										className={classes.closeIcon}
										onClick={() => removeLotNumberRow(row)}
									>
										<Icon src={closeIcon} />
										<span>
											{pageAssets?.request_form_treePlanting_remove_tree}
										</span>
									</span>
								)}
							</div>
						</div>
						<Link
							to=""
							className={classes.modalLinkContainer}
							onClick={() => setIsOpenModal(row.toString())}
							key="openModal"
						>
							{'Sélectionner sur la carte'}
						</Link>
					</>
				)}
			</>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_purchaseOrSell_firstSubStep_title}
							</h4>
						</div>

						<div>
							<strong>{pageAssets[lotRequestReasonInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioListLotRequestReason.find(
											(requestReason) =>
												requestReason.value ==
												answers.lotRequestReason?.toString()
										)?.key!
									]
								}
							</span>
						</div>
						{lotRequestReasonInput.value ==
							REQUEST_CONFIGS_KEYS.requestReason.sell && (
							<>
								<>
									<div>
										<strong>
											{pageAssets[isApplicantLotOwnerInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												pageAssets[
													radioListIsApplicantLotOwner.find(
														(lotOwner) =>
															lotOwner.value ==
															answers.isApplicantLotOwner?.toString()
													)?.key!
												]
											}
										</span>
									</div>
								</>
								<>
									{isApplicantLotOwnerInput.value == 'false' && (
										<>
											{relationToOwnerInput.value != '' && (
												<div>
													<strong>
														{
															pageAssets?.request_form_buildingTransactionRequest_relationToOwnerInput_label
														}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.relationToOwner}
													</span>
												</div>
											)}

											<div>
												<strong>{pageAssets?.label_lastName}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedLastName}
												</span>
											</div>
											<>
												<div>
													<strong>{pageAssets?.label_firstName}</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.concernedFirstName}
													</span>
												</div>
											</>
											<>
												<div>
													<strong>{pageAssets?.label_address}</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.concernedAdresse}
													</span>
												</div>
											</>

											{/* ---------------------------------------------------------------- */}

											{answers?.concernedAppartement && (
												<>
													<div>
														<strong>{`${formatStrapiText(
															pageAssets?.label_apartment_office
														)}`}</strong>
														<br />
														<span className={pageUtils.classes.answer}>
															{answers?.concernedAppartement}
														</span>
													</div>
												</>
											)}

											{/* ---------------------------------------------------------------- */}

											<>
												<div>
													<strong>{pageAssets?.label_email}</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.concernedEmail}
													</span>
												</div>
											</>
											<>
												<div>
													<strong>{pageAssets?.label_telephone}</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.concernedPhoneNumber}
													</span>
												</div>
											</>
										</>
									)}
								</>
							</>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_buildingTransactionRequest_secondSubStep_title
								}
							</h4>

							<div>
								{lotNumbersList.map((lotNumber, index) => {
									if (lotNumber !== '')
										return (
											<>
												<strong>
													{pageAssets?.request_form_buildingTransactionRequest_lotNumbers_label +
														` ${index + 1}`}
												</strong>
												<br />
												<span
													key={`lotNumber${index}`}
													className={pageUtils.classes.answer}
												>
													{lotNumber}
												</span>
											</>
										)
								})}
							</div>
							{answers?.registrationNumber && (
								<div>
									<strong>
										{
											pageAssets?.request_form_buildingTransactionRequest_registrationNumber_label
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.registrationNumber}
									</span>
								</div>
							)}
							{answers?.landAreaDimensions && (
								<div>
									<strong>
										{
											pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_label
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.landAreaDimensions}
										{answers.landAreaMeasurements ===
										Number(
											REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit
												.squaredMeters
										)
											? pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_firstOption_label
											: pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_secondOption_label}
									</span>
								</div>
							)}

							<div className={classes.requestObjectWrapper}>
								<strong>
									{
										pageAssets?.request_form_buildingTransactionRequest_requestObject_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.requestObject}
								</span>
							</div>
						</div>
					</>
				)}
				{subSteps?.requiredDocument &&
					(optionalFilesInput.files.size > 0 ||
						locationCertificateInput.files.size > 0) && (
						<>
							<div>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{pageAssets?.request_form_optional_documents}
								</h4>
							</div>
							{locationCertificateInput.files.size > 0 && (
								<RequestFiles
									subtitle={locationCertificateInput.label}
									name={locationCertificateInput.name}
									files={locationCertificateInput.files}
									hasError={errors.includes(locationCertificateInput.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectLocationCertificateInput}
									maxFiles={locationCertificateInput.numberMaxFiles}
									required={locationCertificateInput.required}
									isSummary
								>
									<></>
								</RequestFiles>
							)}
							{optionalFilesInput.files.size > 0 && (
								<RequestFiles
									subtitle={optionalFilesInput.label}
									name={optionalFilesInput.name}
									files={optionalFilesInput.files}
									hasError={errors.includes(optionalFilesInput.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectOptionalFilesInput}
									maxFiles={optionalFilesInput.numberMaxFiles}
									required={optionalFilesInput.required}
									isSummary
								>
									<></>
								</RequestFiles>
							)}
						</>
					)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{
							pageAssets?.request_form_buildingTransactionRequest_firstSubStep_title
						}
					</h4>

					<RadioInput
						name={lotRequestReasonInput.name}
						label={lotRequestReasonInput.label}
						value={lotRequestReasonInput.value}
						list={radioListLotRequestReason}
						direction={Direction.vertical}
						hasError={errors!.includes(lotRequestReasonInput.name)}
						onChange={onSelectRequestReason}
						ref={lotRequestReasonInput.ref as RefObject<HTMLInputElement>}
						required={lotRequestReasonInput.required}
						hasBackgroundWrapper
					/>
					{showTheClaimantInput && (
						<>
							<RadioInput
								name={isApplicantLotOwnerInput.name}
								label={isApplicantLotOwnerInput.label}
								value={isApplicantLotOwnerInput.value}
								list={radioListIsApplicantLotOwner}
								direction={Direction.vertical}
								hasError={errors!.includes(isApplicantLotOwnerInput.name)}
								onChange={onSelectIsApplicantOwnerInput}
								ref={
									isApplicantLotOwnerInput.ref as RefObject<HTMLInputElement>
								}
								required={isApplicantLotOwnerInput.required}
								hasBackgroundWrapper
							/>

							{isApplicantLotOwnerInput.value.toString() === 'false' && (
								<Notification
									text={
										pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_notification_label
									}
									type="info"
									hasHtml
								/>
							)}

							{showTheClaimantBlock && (
								<>
									<TextInput
										id={relationToOwnerInput.name}
										label={relationToOwnerInput.label}
										value={relationToOwnerInput.value}
										onChange={onSelectRelationToOwnerInput}
										hasError={errors.includes(relationToOwnerInput.name)}
										required={relationToOwnerInput.required}
									/>
									{
										<>
											<ConcernedInfosSection
												errors={errors}
												inputs={inputs}
												onFixError={onFixError}
												onSetInputs={onSetInputs}
												isConcernedInfosRequired={
													isApplicantLotOwnerInput.value == 'false' &&
													lotRequestReasonInput.value ==
														REQUEST_CONFIGS_KEYS.requestReason.sell
												}
												requiredFields={[
													'concernedLastName',
													'concernedFirstName',
													'concernedHasLavalAdresse',
													'concernedEmail',
													'concernedAdresse',
													'concernedPhoneNumber',
													'concernedAppartement',
													'concernedCity',
													'concernedProvince',
													'concernedCountry',
													'concernedPostalCode'
												]}
											/>
										</>
									}
								</>
							)}
						</>
					)}
				</section>
			</Collapse>

			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{
							pageAssets?.request_form_buildingTransactionRequest_secondSubStep_title
						}
					</h4>

					{/* /////////////////  lot Number List  //////////////// */}
					{lotNumbersList.map((lotNumber, index) => {
						return renderLotNumberRow(lotNumber, index)
					})}

					{/* /////////////////  Add Button  //////////////// */}
					{lotNumbersList.length < 4 && (
						<a
							style={{ marginBottom: '12px' }}
							onClick={() => addLotNumbersRow()}
							className={classes.button}
						>
							{pageAssets?.request_form_lotNumbers_add_lot}
						</a>
					)}

					{lotNumbersList.length >= 4 && (
						<p
							className={pageUtils.classes.textFieldContainer}
							style={{ marginBottom: '20px' }}
						>
							{pageAssets?.request_form_lotNumbers_more_lot_label}
						</p>
					)}

					<Modal
						isOpen={isOpenModal !== ''}
						showExitBtn
						onSetIsOpen={() => setIsOpenModal('')}
						type={'default'}
						primaryButtonText={pageAssets?.label_confirm}
						cancelButtonText={pageAssets?.button_cancel}
						onCancelBtn={() => {
							setIsOpenModal('')
						}}
						title={pageAssets?.label_modalTitle_select_a_lot_on_the_map}
						onPrimaryButton={() => setIsOpenModal('')}
					>
						<div className={classes.modalContent}>
							<MapForm
								addressPlaceholder={
									pageAssets?.page_requestInfo_inputAddressPlaceholder
								}
								hasError={false}
								addressRequired={false}
								displayMap={true}
								displayAddressInput={true}
								showButtonUserLocation={isOpenModal !== ''}
								onAddressInputChange={(e) =>
									onSetLotNumbersCurrentInput(e, Number(isOpenModal))
								}
								onAddressChange={(e) =>
									onSetLotNumbersCurrentInput(
										e.cadastre ?? '',
										Number(isOpenModal)
									)
								}
							/>
						</div>
					</Modal>

					{/* IF LOT NUMBERS MORE THAN MAX, INFORM THE USER TO ADD IT IN REQUEST OBJECT */}

					<TextInput
						id={registrationNumberInput.name}
						label={registrationNumberInput.label}
						value={registrationNumberInput.value}
						onChange={onSelectRegistrationNumberInput}
						hasError={errors.includes(registrationNumberInput.name)}
						required={registrationNumberInput.required}
					/>
					<div style={{ width: '500px' }}>
						<div className={classes.rowSection}>
							<TextInput
								id={landAreaDimensionsInput.name}
								label={landAreaDimensionsInput.label}
								value={landAreaDimensionsInput.value}
								onChange={onSelectLandAreaDimensionsInput}
								onKeyPress={(event) => {
									const charCode = event.which ? event.which : event.keyCode
									const char = String.fromCharCode(charCode)
									if (!/^\d$/.test(char)) {
										event.preventDefault()
									}
								}}
								hasError={errors.includes(landAreaDimensionsInput.name)}
								required={landAreaDimensionsInput.required}
								type="number"
								classWrapper={classes.inputContainer}
							/>

							<div className={classes.radioWrapper}>
								<RadioInput
									name={landAreaMeasurementsInput.name}
									label={landAreaMeasurementsInput.label}
									value={landAreaMeasurementsInput.value}
									list={radioListLandAreaDimensions}
									direction={Direction.horizontal}
									hasError={errors!.includes(landAreaMeasurementsInput.name)}
									onChange={onSelectLandAreaMeasurementInput}
									ref={
										landAreaMeasurementsInput.ref as RefObject<HTMLInputElement>
									}
								/>
							</div>
						</div>
					</div>

					<TextAreaInput
						id={requestObjectInput.name}
						label={requestObjectInput.label}
						value={requestObjectInput.value}
						onChange={onSelectRequestObjectInput}
						hasError={errors.includes(requestObjectInput.name)}
						required={requestObjectInput.required}
						ref={requestObjectInput.ref as RefObject<HTMLTextAreaElement>}
						maxCharacters={500}
					/>
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_optional_documents}
					</h4>

					<RequestFiles
						subtitle={locationCertificateInput.label}
						name={locationCertificateInput.name}
						files={locationCertificateInput.files}
						hasError={errors.includes(locationCertificateInput.name)}
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectLocationCertificateInput}
						subSteps={subSteps}
						maxFiles={10}
						required={locationCertificateInput.required}
						description={locationCertificateInput.description}
					></RequestFiles>

					<RequestFiles
						subtitle={optionalFilesInput.label}
						name={optionalFilesInput.name}
						files={optionalFilesInput.files}
						hasError={errors.includes(optionalFilesInput.name)}
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectOptionalFilesInput}
						subSteps={subSteps}
						maxFiles={10}
						required={optionalFilesInput.required}
						description={optionalFilesInput.description}
					></RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default BuildingTransactionRequestForm
