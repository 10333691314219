import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	VehicleInformation
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories,
	Direction
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import { CustomDate } from '@services/models'
import {
	formatHtmlForStrapiText,
	formatStrapiText,
	getFilesFromStore
} from '@utils/methods'
import RadioInput from '@components/requestForms/inputs/radio.input'
import TextAreaInput from './inputs/textarea.inputs'
import BlueBulletsList, {
	BlueBulletsLabel
} from '@components/ui/blueBulletsList'
import Notification from '@components/ui/notification'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import DateFilterInput from './inputs/datefilter.input'

const ClaimNoticeForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		vehicleContainer: string
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		dateAndTimeSection: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
	}

	const classes: Classes = makeClasses({
		vehicleContainer: {
			background: '#eee',
			padding: '20px',
			marginBottom: '10px'
		},
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},
		dateAndTimeSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '24%24%',
			columnGap: '1%',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			'& > div': {
				marginBottom: '0px'
			}
		},
		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			margin: '10px 0',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)
	const MAX_DESCRIPTION_CHARACTERS = 2000
	const MAX_LOCATION_CHARACTERS = 200

	const [showTheConcernedBlock, setShowTheConcernedBlock] = useState<boolean>(
		answers.isConcernedDiffFromApplicant == true ? true : false
	)

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------

	//****** isConcernedDiffFromApplicant inputs ******

	// Faites-vous la réclamation pour un dommage que vous avez subi ou au nom d'une autre personne?
	const [
		isConcernedDiffFromApplicantInput,
		setIsConcernedDiffFromApplicantInput
	] = useState<RequestInputs>({
		name: 'isConcernedDiffFromApplicant',
		label:
			pageAssets?.request_form_claimNotice_isConcernedDiffFromApplicant_label,
		labelKey: 'request_form_claimNotice_isConcernedDiffFromApplicant_label',
		value: answers.isConcernedDiffFromApplicant?.toString() || 'false',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const radioListIsConcernedDiffFromApplicant: RadioList[] = [
		{
			label:
				pageAssets.request_form_claimNotice_isConcernedDiffFromApplicant_firstOption_label,
			value: 'false',
			key: 'request_form_claimNotice_isConcernedDiffFromApplicant_firstOption_label'
		},
		{
			label:
				pageAssets.request_form_claimNotice_isConcernedDiffFromApplicant_secondOption_label,
			value: 'true',
			key: 'request_form_claimNotice_isConcernedDiffFromApplicant_secondOption_label'
		}
	]

	////////////////////////////////////////////////////////////////

	//----------------------------------------------------------------
	//-------------------- DESCRIPTION INPUTS ------------------------
	//----------------------------------------------------------------

	// Nature de la déclaration
	const [claimNatureInput, setClaimNatureInput] = useState<RequestInputs>({
		name: 'claimNature',
		label: pageAssets?.request_form_claimNotice_claimNature_label,
		labelKey: 'request_form_claimNotice_claimNature_label',
		value: answers.claimNature?.toString() || '',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		description: pageAssets?.request_form_claimNotice_claimNature_label,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [isDamageLimitedToGrassInput, setIsDamageLimitedToGrassInput] =
		useState<RequestInputs>({
			name: 'isDamageLimitedToGrass',
			label: pageAssets?.request_form_claimNotice_isDamageLimitedToGrass_label,
			labelKey: 'request_form_claimNotice_isDamageLimitedToGrass_label',
			value: answers.isDamageLimitedToGrass?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_isDamageLimitedToGrass_description
		})

	const [materialDamageInput, setMaterialDamageInput] = useState<RequestInputs>(
		{
			name: 'materialDamage',
			label: pageAssets?.request_form_claimNotice_materialDamage_label,
			labelKey: 'request_form_claimNotice_materialDamage_label',
			value: answers.materialDamage?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_materialDamage_description
		}
	)

	const [isDamageLimitedToBinInput, setIsDamageLimitedToBinInput] =
		useState<RequestInputs>({
			name: 'isDamageLimitedToBin',
			label: pageAssets?.request_form_claimNotice_isDamageLimitedToBin_label,
			labelKey: 'request_form_claimNotice_isDamageLimitedToBin_label',
			value: answers.isDamageLimitedToBin?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_isDamageLimitedToBin_description
		})

	const [isOwnerOfDamagedVehicleInput, setIsOwnerOfDamagedVehicleInput] =
		useState<RequestInputs>({
			name: 'isOwnerOfDamagedVehicle',
			label: pageAssets?.request_form_claimNotice_isOwnerOfDamagedVehicle_label,
			labelKey: 'request_form_claimNotice_isOwnerOfDamagedVehicle_label',
			value: answers.isOwnerOfDamagedVehicle?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_isOwnerOfDamagedVehicle_description
		})

	const [
		actualOwnerOfDamagedVehicleInput,
		setActualOwnerOfDamagedVehicleInput
	] = useState<RequestInputs>({
		name: 'actualOwnerOfDamagedVehicle',
		label:
			pageAssets?.request_form_claimNotice_actualOwnerOfDamagedVehicle_label,
		labelKey: 'request_form_claimNotice_actualOwnerOfDamagedVehicle_label',
		value: answers.actualOwnerOfDamagedVehicle || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		description:
			pageAssets?.request_form_claimNotice_actualOwnerOfDamagedVehicle_description
	})

	const [showTheOwnerNameInput, setShowTheOwnerNameInput] =
		useState<boolean>(false)

	const [isOwnerOrRenterInput, setIsOwnerOrRenterInput] =
		useState<RequestInputs>({
			name: 'isOwnerOrRenter',
			label: pageAssets?.request_form_claimNotice_isOwnerOrRenter_label,
			labelKey: 'request_form_claimNotice_isOwnerOrRenter_label',
			value: answers.isOwnerOrRenter?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_isOwnerOrRenter_description
		})

	const [vehicleInformation, setVehicleInformation] =
		useState<VehicleInformation>(
			answers?.vehicleInformation
				? answers.vehicleInformation
				: {
						brand: '',
						model: '',
						color: '',
						registration_number: ''
				  }
		)

	// a fake RequestInputs to put vehicleInformation data in
	// value will be encoded as JSON
	const [vehicleInformationInput, setVehicleInformationInput] =
		useState<RequestInputs>({
			name: 'vehicleInformation',
			label: pageAssets?.request_form_claimNotice_vehicleInformation_title,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_information_title',
			value: JSON.stringify(vehicleInformation),
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const radioListYesOrNo: RadioList[] = [
		{
			label: pageAssets.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioListOwnerOrRenter: RadioList[] = [
		{
			label: pageAssets.select_ownerStatus_owner,
			value: REQUEST_CONFIGS_KEYS.tenantOrOwner.owner,
			key: 'select_ownerStatus_owner'
		},
		{
			label: pageAssets.select_ownerStatus_tenant,
			value: REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant,
			key: 'select_ownerStatus_tenant'
		}
	]

	// Lieu
	const [incidentLocationInput, setIncidentLocationInput] =
		useState<RequestInputs>({
			name: 'incidentLocation',
			label: pageAssets?.request_form_claimNotice_incidentLocation_label,
			labelKey: 'request_form_claimNotice_incidentLocation_label',
			value: answers.incidentLocation || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			description:
				pageAssets?.request_form_claimNotice_incidentLocation_description
		})

	// Date et heure de l'incident
	const [incidentDateInput, setIncidentDateInput] = useState<RequestInputs>({
		name: 'incidentDate',
		label: pageAssets?.request_form_claimNotice_incidentDate_label,
		labelKey: 'request_form_claimNotice_incidentDate_label',
		value: answers.incidentDate || '',
		required: answers.isDateAndTimeNotKnown
			? !answers.isDateAndTimeNotKnown
			: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [incidentTimeInput, setIncidentTimeInput] = useState<RequestInputs>({
		name: 'incidentTime',
		label: pageAssets?.request_form_claimNotice_incidentTime_label,
		labelKey: 'request_form_claimNotice_incidentTime_label',
		value: answers.incidentTime || '',
		required: answers.isDateAndTimeNotKnown
			? !answers.isDateAndTimeNotKnown
			: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	// Je ne sais pas CHECKBOX
	const [isDateAndTimeNotKnownInput, setIsDateAndTimeNotKnownInput] =
		useState<RequestInputs>({
			name: 'isDateAndTimeNotKnown',
			label: pageAssets?.request_form_claimNotice_isDateAndTimeNotKnown_label,
			labelKey: 'request_form_claimNotice_isDateAndTimeNotKnown_label',
			value: answers.isDateAndTimeNotKnown == true ? 'true' : 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Date de la découverte des dommages
	const [damageDiscoveryDateInput, setDamageDiscoveryDateInput] =
		useState<RequestInputs>({
			name: 'damageDiscoveryDate',
			label: pageAssets?.request_form_claimNotice_damageDiscoveryDate_label,
			labelKey: 'request_form_claimNotice_damageDiscoveryDate_label',
			value: answers.damageDiscoveryDate || '',
			required: answers.isDateAndTimeNotKnown ?? false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Numéro de rapport de police (si applicable)
	const [policeReportNumberInput, setPoliceReportNumberInput] =
		useState<RequestInputs>({
			name: 'policeReportNumber',
			label: pageAssets?.request_form_claimNotice_policeReportNumber_label,
			labelKey: 'request_form_claimNotice_policeReportNumber_label',
			value: answers.policeReportNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Numéro de la requête au 311 (si applicable)
	const [request311NumberInput, setRequest311NumberInput] =
		useState<RequestInputs>({
			name: 'request311Number',
			label: pageAssets?.request_form_claimNotice_request311Number_label,
			labelKey: 'request_form_claimNotice_request311Number_label',
			value: answers.request311Number || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Brève description de la cause des dommages
	const [damageCauseDescriptionInput, setDamageCauseDescriptionInput] =
		useState<RequestInputs>({
			name: 'damageCauseDescription',
			label: pageAssets?.request_form_claimNotice_damageCauseDescription_label,
			labelKey: 'request_form_claimNotice_damageCauseDescription_label',
			value: answers.damageCauseDescription || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//Brève description des dommages
	const [damageDescriptionInput, setDamageDescriptionInput] =
		useState<RequestInputs>({
			name: 'damageDescription',
			label: pageAssets?.request_form_claimNotice_damageDescription_label,
			labelKey: 'request_form_claimNotice_damageDescription_label',
			value: answers.damageDescription || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// Photo(s) ou autre(s) document(s) pertinent(s)
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_claimNotice_firstInput_label,
		labelKey: 'request_form_claimNotice_firstInput_label',
		description: pageAssets?.request_form_claimNotice_firstInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		category: requestCategories.other
	})

	// const firstQuestionListDescription: BlueBulletsLabel[] = [
	// 	{
	// 		name: pageAssets?.request_form_claimNotice_firstInput_list_firstDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_claimNotice_firstInput_list_secondDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_claimNotice_firstInput_list_thirdDesc_label
	// 	}
	// ]

	// ------------- DETAILS INPUTS SETTERS ----------------

	const onSelectIsConcernedDiffFromApplicantInput = (value: string) => {
		setIsConcernedDiffFromApplicantInput({
			...isConcernedDiffFromApplicantInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'true') {
				setShowTheConcernedBlock(true)
			} else {
				setShowTheConcernedBlock(false)
			}
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				isConcernedDiffFromApplicantInput.name
			)
		)
	}

	const onSelectClaimNatureInput = (value: string) => {
		setClaimNatureInput({ ...claimNatureInput, value: `${value}` })

		onFixError(UpdateRequestInputsErrorsArray(errors, claimNatureInput.name))
	}

	const onSelectIncidentLocationInput = (value: string) => {
		setIncidentLocationInput({ ...incidentLocationInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, incidentLocationInput.name)
		)
	}

	const onSelectIncidentDateInput = (value: string) => {
		let date =
			incidentTimeInput.value !== ''
				? getIncidentDate(value) + 'T' + incidentTimeInput.value
				: getIncidentDate(value)

		setIncidentDateInput({ ...incidentDateInput, value: date })

		onFixError(UpdateRequestInputsErrorsArray(errors, incidentDateInput.name))
	}

	const onSelectIncidentTimeInput = (value) => {
		setIncidentTimeInput({ ...incidentTimeInput, value: value })

		let date =
			value !== ''
				? getIncidentDate(incidentDateInput.value) + 'T' + value
				: getIncidentDate(incidentDateInput.value)

		incidentDateInput.value !== '' &&
			setIncidentDateInput({ ...incidentDateInput, value: date })

		onFixError(UpdateRequestInputsErrorsArray(errors, incidentTimeInput.name))
	}

	const onSelectIsDateAndTimeNotKnownInput = (value) => {
		setIsDateAndTimeNotKnownInput({
			...isDateAndTimeNotKnownInput,
			value: `${value}`
		})

		if (value == true) {
			setIncidentDateInput({ ...incidentDateInput, required: false, value: '' })
			setIncidentTimeInput({ ...incidentTimeInput, required: false, value: '' })

			setDamageDiscoveryDateInput({
				...damageDiscoveryDateInput,
				required: true
			})
		} else {
			setIncidentDateInput({ ...incidentDateInput, required: true })
			setIncidentTimeInput({ ...incidentTimeInput, required: true })

			setDamageDiscoveryDateInput({
				...damageDiscoveryDateInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isDateAndTimeNotKnownInput.name,
						incidentDateInput.name,
						incidentTimeInput.name,
						damageDiscoveryDateInput.name
					].includes(error)
			)
		)
	}

	const onSelectDamageDiscoveryDateInput = (value: string) => {
		setDamageDiscoveryDateInput({ ...damageDiscoveryDateInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, damageDiscoveryDateInput.name)
		)
	}

	const onSelectPoliceReportNumberInput = (value: string) => {
		setPoliceReportNumberInput({ ...policeReportNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, policeReportNumberInput.name)
		)
	}

	const onSelectRequest311NumberInput = (value: string) => {
		setRequest311NumberInput({ ...request311NumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, request311NumberInput.name)
		)
	}

	const onSelectDamageCauseDescriptionInput = (value: string) => {
		setDamageCauseDescriptionInput({ ...damageCauseDescriptionInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, damageCauseDescriptionInput.name)
		)
	}

	const onSelectDamageDescriptionInput = (value: string) => {
		setDamageDescriptionInput({ ...damageDescriptionInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, damageDescriptionInput.name)
		)
	}

	const onSelectIsDamageLimitedToGrassInput = (value: string) => {
		setIsDamageLimitedToGrassInput({ ...isDamageLimitedToGrassInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isDamageLimitedToGrassInput.name)
		)
	}

	const onSelectMaterialDamageInput = (value: string) => {
		setMaterialDamageInput({ ...materialDamageInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, materialDamageInput.name))
	}

	const onSelectIsDamageLimitedToBinInput = (value: string) => {
		setIsDamageLimitedToBinInput({ ...isDamageLimitedToBinInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isDamageLimitedToBinInput.name)
		)
	}

	const onSelectIsOwnerOfDamagedVehicleInput = (value: string) => {
		setIsOwnerOfDamagedVehicleInput({
			...isOwnerOfDamagedVehicleInput,
			value: `${value}`
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isOwnerOfDamagedVehicleInput.name)
		)
	}

	const onSelectActualOwnerOfDamagedVehicleInput = (value: string) => {
		setActualOwnerOfDamagedVehicleInput({
			...actualOwnerOfDamagedVehicleInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				actualOwnerOfDamagedVehicleInput.name
			)
		)
	}

	const onSelectIsOwnerOrRenterInput = (value: string) => {
		setIsOwnerOrRenterInput({ ...isOwnerOrRenterInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isOwnerOrRenterInput.name)
		)
	}

	const radioClaimNature: RadioList[] = [
		{
			label: pageAssets?.request_form_claimNotice_claimNature_snowRemoval_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.snowRemoval,
			key: 'request_form_claimNotice_claimNature_snowRemoval_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_flooding_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.flooding,
			key: 'request_form_claimNotice_claimNature_flooding_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_flooding_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_roadWeatheringDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage,
			key: 'request_form_claimNotice_claimNature_roadWeatheringDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_plumberFees_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
			key: 'request_form_claimNotice_claimNature_plumberFees_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_plumberFees_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_roadWorkDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.roadWorkDamage,
			key: 'request_form_claimNotice_claimNature_roadWorkDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_carAccident_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.carAccident,
			key: 'request_form_claimNotice_claimNature_carAccident_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_carAccident_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_bodilyInjury_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury,
			key: 'request_form_claimNotice_claimNature_bodilyInjury_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_trashCollectionDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage,
			key: 'request_form_claimNotice_claimNature_trashCollectionDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_treeFall_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.treeFall,
			key: 'request_form_claimNotice_claimNature_treeFall_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_waterMainBreak_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak,
			key: 'request_form_claimNotice_claimNature_waterMainBreak_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_seizedGoodsDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.seizedGoodsDamage,
			key: 'request_form_claimNotice_claimNature_seizedGoodsDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_towingDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.towingDamage,
			key: 'request_form_claimNotice_claimNature_towingDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_policeInterventionDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.policeInterventionDamage,
			key: 'request_form_claimNotice_claimNature_policeInterventionDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_firefighterDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.firefighterDamage,
			key: 'request_form_claimNotice_claimNature_firefighterDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_other_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.other,
			key: 'request_form_claimNotice_claimNature_other_label'
		}
	]

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, isConcernedDiffFromApplicantInput)
		)
	}, [isConcernedDiffFromApplicantInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, claimNatureInput))

		let isDamageLimitedToGrassRequired = false
		let isMaterialDamageRequired = false
		let isDamageLimitedToBinRequired = false
		let isOwnerOfDamagedVehicleRequired = false
		let isOwnerOrRenterRequired = false
		let isVehicleInfoRequired = false

		switch (claimNatureInput.value) {
			case REQUEST_CONFIGS_KEYS.claimNature.snowRemoval:
				isDamageLimitedToGrassRequired = true
				break
			case REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury:
				isMaterialDamageRequired = true
				break
			case REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage:
				isDamageLimitedToBinRequired = true
				break
			case REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage:
			case REQUEST_CONFIGS_KEYS.claimNature.carAccident:
				isOwnerOfDamagedVehicleRequired = true
				if (isOwnerOfDamagedVehicleInput.value !== 'false') {
					setShowTheOwnerNameInput(false)
				}
				break
			case REQUEST_CONFIGS_KEYS.claimNature.flooding:
			case REQUEST_CONFIGS_KEYS.claimNature.plumberFees:
			case REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak:
				isOwnerOrRenterRequired = true
				if (
					isOwnerOrRenterInput.value !==
					REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant
				) {
					setShowTheOwnerNameInput(false)
				}
				break
			case REQUEST_CONFIGS_KEYS.claimNature.towingDamage:
				isOwnerOfDamagedVehicleRequired = true
				isVehicleInfoRequired = true
				break
		}
		setIsDamageLimitedToGrassInput({
			...isDamageLimitedToGrassInput,
			required: isDamageLimitedToGrassRequired
		})
		setMaterialDamageInput({
			...materialDamageInput,
			required: isMaterialDamageRequired
		})
		setIsDamageLimitedToBinInput({
			...isDamageLimitedToBinInput,
			required: isDamageLimitedToBinRequired
		})
		setIsOwnerOfDamagedVehicleInput({
			...isOwnerOfDamagedVehicleInput,
			required: isOwnerOfDamagedVehicleRequired
		})
		setIsOwnerOrRenterInput({
			...isOwnerOrRenterInput,
			required: isOwnerOrRenterRequired
		})
		setVehicleInformationInput({
			...vehicleInformationInput,
			required: isVehicleInfoRequired
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						isDamageLimitedToGrassInput.name,
						materialDamageInput.name,
						isDamageLimitedToBinInput.name,
						isOwnerOfDamagedVehicleInput.name,
						isOwnerOrRenterInput.name,
						vehicleInformationInput.name,
						`vehicleInformationModel`,
						`vehicleInformationBrand`,
						`vehicleInformationColor`,
						`vehicleInformationRegistration_number`
					].includes(error)
			)
		)
	}, [claimNatureInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isDamageLimitedToGrassInput))
	}, [isDamageLimitedToGrassInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, materialDamageInput))
	}, [materialDamageInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isDamageLimitedToBinInput))
	}, [isDamageLimitedToBinInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isOwnerOfDamagedVehicleInput))

		let requiredCondition = isOwnerOfDamagedVehicleInput.value !== 'true'

		setActualOwnerOfDamagedVehicleInput({
			...actualOwnerOfDamagedVehicleInput,
			required: requiredCondition
		})
		setShowTheOwnerNameInput(requiredCondition)

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				actualOwnerOfDamagedVehicleInput.name
			)
		)
	}, [isOwnerOfDamagedVehicleInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, actualOwnerOfDamagedVehicleInput)
		)
	}, [actualOwnerOfDamagedVehicleInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isOwnerOrRenterInput))

		let requiredCondition =
			isOwnerOrRenterInput.value === REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant

		setActualOwnerOfDamagedVehicleInput({
			...actualOwnerOfDamagedVehicleInput,
			required: requiredCondition
		})
		setShowTheOwnerNameInput(requiredCondition)

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				actualOwnerOfDamagedVehicleInput.name
			)
		)
	}, [isOwnerOrRenterInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, vehicleInformationInput))
	}, [vehicleInformationInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, incidentLocationInput))
	}, [incidentLocationInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, incidentLocationInput))
	}, [incidentLocationInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, incidentDateInput))
	}, [incidentDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, incidentTimeInput))
	}, [incidentTimeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isDateAndTimeNotKnownInput))
	}, [isDateAndTimeNotKnownInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, damageDiscoveryDateInput))
	}, [damageDiscoveryDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, policeReportNumberInput))
	}, [policeReportNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, request311NumberInput))
	}, [request311NumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, damageCauseDescriptionInput))
	}, [damageCauseDescriptionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, damageDescriptionInput))
	}, [damageDescriptionInput])

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const updateVehicleInformationErrorsArray = (
		vehicleInformation: VehicleInformation
	): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('vehicleInformation')) {
				if (
					vehicleInformation.brand == '' &&
					error == `vehicleInformationBrand`
				) {
					errorArray.push(error)
				}
				if (
					vehicleInformation.model == '' &&
					error == `vehicleInformationModel`
				) {
					errorArray.push(error)
				}
				if (
					vehicleInformation.color == '' &&
					error == `vehicleInformationColor`
				) {
					errorArray.push(error)
				}
				if (
					vehicleInformation.registration_number == '' &&
					error == `vehicleInformationRegistration_number`
				) {
					errorArray.push(error)
				}
				// if (
				// 	vehicleInformation.onCompanyName == '' &&
				// 	error == `vehicleInformationOnCompanyName`
				// ) {
				// 	errorArray.push(error)
				// }
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	const onSetVehicleInformationCurrentInput = (value: string, currentInput) => {
		const { attribute } = currentInput

		let item = JSON.parse(JSON.stringify(vehicleInformation))

		if (value !== '' && Number(value) < 0) {
			return
		}

		item[`${attribute}`] = value

		setVehicleInformation(item)

		setVehicleInformationInput({
			...vehicleInformationInput,
			value: JSON.stringify(item)
		})

		onFixError(updateVehicleInformationErrorsArray(item))
	}

	const renderVehicleInformation = (vehicleInformation: VehicleInformation) => {
		return (
			<>
				<div className={classes.vehicleContainer}>
					<TextInput
						id={`vehicleInformationBrand`}
						label={
							pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label
						}
						value={vehicleInformation.brand!}
						onChange={(e) =>
							onSetVehicleInformationCurrentInput(e, {
								attribute: 'brand'
							})
						}
						hasError={errors.includes(`vehicleInformationBrand`)}
						required={vehicleInformationInput.required}
					/>

					<TextInput
						id={`vehicleInformationModel`}
						label={
							pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label
						}
						value={vehicleInformation.model!}
						onChange={(e) =>
							onSetVehicleInformationCurrentInput(e, {
								attribute: 'model'
							})
						}
						hasError={errors.includes(`vehicleInformationModel`)}
						required={vehicleInformationInput.required}
					/>

					<TextInput
						id={`vehicleInformationColor`}
						label={
							pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
						}
						value={vehicleInformation.color!}
						onChange={(e) =>
							onSetVehicleInformationCurrentInput(e, {
								attribute: 'color'
							})
						}
						hasError={errors.includes(`vehicleInformationColor`)}
						required={vehicleInformationInput.required}
					/>

					<TextInput
						id={`vehicleInformationRegistration_number`}
						label={
							pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label
						}
						value={vehicleInformation.registration_number!}
						onChange={(e) =>
							onSetVehicleInformationCurrentInput(e, {
								attribute: 'registration_number'
							})
						}
						hasError={errors.includes(`vehicleInformationRegistration_number`)}
						required={vehicleInformationInput.required}
					/>
				</div>
			</>
		)
	}

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getIncidentDate = (value) => {
		let date = value?.split('T', 1)

		if (date) {
			return date[0]
		}

		return ''
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_claimNotice_firstSubStep_title}
							</h4>
						</div>

						{/* <h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_claimNotice_firstSubStep_title}
						</h5> */}

						<div>
							<strong>
								{pageAssets[isConcernedDiffFromApplicantInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioListIsConcernedDiffFromApplicant.find(
											(claim) =>
												claim.value ==
												answers.isConcernedDiffFromApplicant?.toString()
										)?.key!
									]
								}
							</span>
						</div>

						{showTheConcernedBlock && (
							<>
								<div>
									<strong>{pageAssets?.label_lastName}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.concernedLastName}
									</span>
								</div>
								<>
									<div>
										<strong>{pageAssets?.label_firstName}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedFirstName}
										</span>
									</div>
								</>
								<>
									<div>
										<strong>{pageAssets?.label_address}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAdresse}
										</span>
									</div>
								</>
								<>
									<div>
										<strong>{`${formatStrapiText(
											pageAssets?.label_apartment_office
										)}`}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAppartement}
										</span>
									</div>
								</>

								{/* ---------------------------------------------------------------- */}
								{answers?.concernedHasLavalAdresse?.toString() == 'false' && (
									<>
										<>
											<div>
												<strong>{pageAssets?.label_city}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedCity}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_state}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedProvince}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_postalCode}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedPostalCode}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_country}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedCountry}
												</span>
											</div>
										</>
									</>
								)}
								{/* ---------------------------------------------------------------- */}

								<>
									<div>
										<strong>{pageAssets?.label_email}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedEmail}
										</span>
									</div>
								</>
								<>
									<div>
										<strong>{pageAssets?.label_telephone}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedPhoneNumber}
										</span>
									</div>
								</>
							</>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_claimNotice_secondSubStep_title}
							</h4>
						</div>

						<>
							<div>
								<strong>{pageAssets[claimNatureInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{
										pageAssets[
											radioClaimNature.find(
												(nature) =>
													nature.value == answers.claimNature?.toString()
											)?.key!
										]
									}
								</span>
							</div>

							{answers?.claimNature?.toString() ===
								REQUEST_CONFIGS_KEYS.claimNature.snowRemoval && (
								<div>
									<strong>
										{pageAssets[isDamageLimitedToGrassInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{
											pageAssets[
												radioListYesOrNo.find(
													(item) =>
														item.value ==
														answers.isDamageLimitedToGrass?.toString()
												)?.key!
											]
										}
									</span>
								</div>
							)}

							{answers?.claimNature?.toString() ===
								REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage && (
								<div>
									<strong>
										{pageAssets[isDamageLimitedToBinInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{
											pageAssets[
												radioListYesOrNo.find(
													(item) =>
														item.value ==
														answers.isDamageLimitedToBin?.toString()
												)?.key!
											]
										}
									</span>
								</div>
							)}
							{[
								REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage,
								REQUEST_CONFIGS_KEYS.claimNature.carAccident,
								REQUEST_CONFIGS_KEYS.claimNature.towingDamage
							].includes(answers?.claimNature?.toString()!) && (
								<>
									<div>
										<strong>
											{pageAssets[isOwnerOfDamagedVehicleInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												pageAssets[
													radioListYesOrNo.find(
														(item) =>
															item.value ==
															answers.isOwnerOfDamagedVehicle?.toString()
													)?.key!
												]
											}
										</span>
									</div>

									{answers.isOwnerOfDamagedVehicle?.toString() === 'false' && (
										<div>
											<strong>
												{pageAssets[actualOwnerOfDamagedVehicleInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.actualOwnerOfDamagedVehicle}
											</span>
										</div>
									)}
								</>
							)}

							{[
								REQUEST_CONFIGS_KEYS.claimNature.flooding,
								REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
								REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak
							].includes(answers?.claimNature?.toString()!) && (
								<>
									<div>
										<strong>{pageAssets[isOwnerOrRenterInput.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												pageAssets[
													radioListOwnerOrRenter.find(
														(item) =>
															item.value == answers.isOwnerOrRenter?.toString()
													)?.key!
												]
											}
										</span>
									</div>

									{answers.isOwnerOrRenter?.toString() ===
										REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant && (
										<div>
											<strong>
												{pageAssets[actualOwnerOfDamagedVehicleInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.actualOwnerOfDamagedVehicle}
											</span>
										</div>
									)}
								</>
							)}

							{answers?.claimNature?.toString() ===
								REQUEST_CONFIGS_KEYS.claimNature.towingDamage && (
								<>
									<div>
										<strong>
											{
												pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label
											}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.vehicleInformation?.brand}
										</span>
									</div>

									<div>
										<strong>
											{
												pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label
											}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.vehicleInformation?.model}
										</span>
									</div>

									<div>
										<strong>
											{
												pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
											}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.vehicleInformation?.color}
										</span>
									</div>

									<div>
										<strong>
											{
												pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label
											}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.vehicleInformation?.registration_number}
										</span>
									</div>
								</>
							)}

							<div>
								<strong>{pageAssets[incidentLocationInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.incidentLocation}
								</span>
							</div>

							{!answers?.isDateAndTimeNotKnown ? (
								<>
									<div>
										<strong>{pageAssets[incidentDateInput.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{pageUtils.getLocaleDate(answers?.incidentDate!)}{' '}
											{answers?.incidentTime}
										</span>
									</div>
								</>
							) : (
								<>
									<div>
										<strong>
											{pageAssets[damageDiscoveryDateInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{pageUtils.getLocaleDate(answers?.damageDiscoveryDate!)}
										</span>
									</div>
								</>
							)}

							{answers?.policeReportNumber && (
								<div>
									<strong>
										{pageAssets[policeReportNumberInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.policeReportNumber}
									</span>
								</div>
							)}

							{answers?.request311Number && (
								<div>
									<strong>{pageAssets[request311NumberInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.request311Number}
									</span>
								</div>
							)}

							{answers?.damageCauseDescription && (
								<div>
									<strong>
										{pageAssets[damageCauseDescriptionInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.damageCauseDescription}
									</span>
								</div>
							)}

							{answers?.damageDescription && (
								<div>
									<strong>{pageAssets[damageDescriptionInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.damageDescription}
									</span>
								</div>
							)}
						</>
					</>
				)}

				{subSteps?.requiredDocument && firstQuestion.files.size > 0 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{
									display: toPrint ? 'none' : 'flex',
									float: 'right'
								}}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFiles
							subtitle={firstQuestion.label}
							name={firstQuestion.name}
							required={firstQuestion.required}
							files={firstQuestion.files}
							hasError={errors.includes(firstQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFirstQuestion}
							maxFiles={firstQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_claimNotice_firstSubStep_title}
					</h4>

					<RadioInput
						name={isConcernedDiffFromApplicantInput.name}
						label={isConcernedDiffFromApplicantInput.label}
						value={isConcernedDiffFromApplicantInput.value}
						list={radioListIsConcernedDiffFromApplicant}
						direction={Direction.vertical}
						hasError={errors!.includes(isConcernedDiffFromApplicantInput.name)}
						onChange={onSelectIsConcernedDiffFromApplicantInput}
						ref={
							isConcernedDiffFromApplicantInput.ref as RefObject<HTMLInputElement>
						}
						required={isConcernedDiffFromApplicantInput.required}
						hasBackgroundWrapper
					/>

					{showTheConcernedBlock && (
						<>
							{
								<>
									<Notification
										text={formatStrapiText(
											pageAssets?.request_form_claimNotice_concernedDiffFromApplicant_infoTitle
										)}
										type="info"
										hasHtml
									/>

									<ConcernedInfosSection
										errors={errors}
										inputs={inputs}
										onFixError={onFixError}
										onSetInputs={onSetInputs}
										isConcernedInfosRequired={
											isConcernedDiffFromApplicantInput.value == 'true'
										}
									/>
								</>
							}
						</>
					)}
				</section>
			</Collapse>

			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_claimNotice_secondSubStep_title}
					</h4>

					<RadioInput
						name={claimNatureInput.name}
						label={claimNatureInput.label}
						value={claimNatureInput.value}
						list={radioClaimNature}
						direction={Direction.vertical}
						hasError={errors!.includes(claimNatureInput.name)}
						onChange={onSelectClaimNatureInput}
						ref={claimNatureInput.ref as RefObject<HTMLInputElement>}
						required={claimNatureInput.required}
						hasBackgroundWrapper
					/>

					{claimNatureInput.value ===
						REQUEST_CONFIGS_KEYS.claimNature.snowRemoval && (
						<>
							<RadioInput
								name={isDamageLimitedToGrassInput.name}
								label={isDamageLimitedToGrassInput.label}
								value={isDamageLimitedToGrassInput.value}
								list={radioListYesOrNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isDamageLimitedToGrassInput.name)}
								onChange={onSelectIsDamageLimitedToGrassInput}
								ref={
									isDamageLimitedToGrassInput.ref as RefObject<HTMLInputElement>
								}
								required={isDamageLimitedToGrassInput.required}
								hasBackgroundWrapper
							/>

							{isDamageLimitedToGrassInput.value === 'true' && (
								<Notification
									text={formatHtmlForStrapiText(
										isDamageLimitedToGrassInput.description
									)}
									type="info"
									hasHtml
									selfRedirect
								/>
							)}
						</>
					)}

					{[
						REQUEST_CONFIGS_KEYS.claimNature.flooding,
						REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
						REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak
					].includes(claimNatureInput.value) && (
						<Notification
							text={formatStrapiText(
								pageAssets?.request_form_claimNotice_callInsurer_label
							)}
							type="info"
							hasHtml
						/>
					)}

					{claimNatureInput.value ===
						REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury && (
						<RadioInput
							name={materialDamageInput.name}
							label={materialDamageInput.label}
							value={materialDamageInput.value}
							list={radioListYesOrNo}
							direction={Direction.vertical}
							hasError={errors!.includes(materialDamageInput.name)}
							onChange={onSelectMaterialDamageInput}
							ref={materialDamageInput.ref as RefObject<HTMLInputElement>}
							required={materialDamageInput.required}
							hasBackgroundWrapper
						/>
					)}

					{claimNatureInput.value ===
						REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage && (
						<>
							<RadioInput
								name={isDamageLimitedToBinInput.name}
								label={isDamageLimitedToBinInput.label}
								value={isDamageLimitedToBinInput.value}
								list={radioListYesOrNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isDamageLimitedToBinInput.name)}
								onChange={onSelectIsDamageLimitedToBinInput}
								ref={
									isDamageLimitedToBinInput.ref as RefObject<HTMLInputElement>
								}
								required={isDamageLimitedToBinInput.required}
								hasBackgroundWrapper
							/>

							{isDamageLimitedToBinInput.value === 'true' && (
								<Notification
									text={formatHtmlForStrapiText(
										isDamageLimitedToBinInput.description
									)}
									type="info"
									hasHtml
								/>
							)}
						</>
					)}

					{[
						REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage,
						REQUEST_CONFIGS_KEYS.claimNature.carAccident,
						REQUEST_CONFIGS_KEYS.claimNature.towingDamage
					].includes(claimNatureInput.value) && (
						<>
							<RadioInput
								name={isOwnerOfDamagedVehicleInput.name}
								label={isOwnerOfDamagedVehicleInput.label}
								value={isOwnerOfDamagedVehicleInput.value}
								list={radioListYesOrNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isOwnerOfDamagedVehicleInput.name)}
								onChange={onSelectIsOwnerOfDamagedVehicleInput}
								ref={
									isOwnerOfDamagedVehicleInput.ref as RefObject<HTMLInputElement>
								}
								required={isOwnerOfDamagedVehicleInput.required}
								hasBackgroundWrapper
							/>

							{isOwnerOfDamagedVehicleInput.value === 'false' && (
								<TextInput
									id={actualOwnerOfDamagedVehicleInput.name}
									label={actualOwnerOfDamagedVehicleInput.label}
									value={actualOwnerOfDamagedVehicleInput.value}
									onChange={onSelectActualOwnerOfDamagedVehicleInput}
									hasError={errors.includes(
										actualOwnerOfDamagedVehicleInput.name
									)}
									required={actualOwnerOfDamagedVehicleInput.required}
									ref={
										actualOwnerOfDamagedVehicleInput.ref as RefObject<HTMLInputElement>
									}
									classWrapper={classes.inputContainer}
									description={actualOwnerOfDamagedVehicleInput.description}
								/>
							)}
						</>
					)}

					{[
						REQUEST_CONFIGS_KEYS.claimNature.flooding,
						REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
						REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak
					].includes(claimNatureInput.value) && (
						<>
							<RadioInput
								name={isOwnerOrRenterInput.name}
								label={isOwnerOrRenterInput.label}
								value={isOwnerOrRenterInput.value}
								list={radioListOwnerOrRenter}
								direction={Direction.vertical}
								hasError={errors!.includes(isOwnerOrRenterInput.name)}
								onChange={onSelectIsOwnerOrRenterInput}
								ref={isOwnerOrRenterInput.ref as RefObject<HTMLInputElement>}
								required={isOwnerOrRenterInput.required}
								hasBackgroundWrapper
							/>

							{isOwnerOrRenterInput.value ===
								REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant && (
								<TextInput
									id={actualOwnerOfDamagedVehicleInput.name}
									label={actualOwnerOfDamagedVehicleInput.label}
									value={actualOwnerOfDamagedVehicleInput.value}
									onChange={onSelectActualOwnerOfDamagedVehicleInput}
									hasError={errors.includes(
										actualOwnerOfDamagedVehicleInput.name
									)}
									required={actualOwnerOfDamagedVehicleInput.required}
									ref={
										actualOwnerOfDamagedVehicleInput.ref as RefObject<HTMLInputElement>
									}
									classWrapper={classes.inputContainer}
									description={actualOwnerOfDamagedVehicleInput.description}
									maxCharacters={100}
								/>
							)}
						</>
					)}

					{claimNatureInput.value ===
						REQUEST_CONFIGS_KEYS.claimNature.towingDamage && (
						<>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_claimNotice_vehicleInformation_title}
							</h5>
							<div className={classes.vehicleContainer}>
								{renderVehicleInformation(vehicleInformation)}
							</div>
						</>
					)}

					<h5 className={pageUtils.classes.h5}>
						{pageAssets?.request_form_claimNotice_eventDescription_label}
					</h5>

					<TextInput
						id={incidentLocationInput.name}
						label={incidentLocationInput.label}
						value={incidentLocationInput.value}
						onChange={onSelectIncidentLocationInput}
						hasError={errors.includes(incidentLocationInput.name)}
						required={incidentLocationInput.required}
						ref={incidentLocationInput.ref as RefObject<HTMLInputElement>}
						classWrapper={classes.inputContainer}
						description={incidentLocationInput.description}
						maxCharacters={MAX_LOCATION_CHARACTERS}
					/>

					<div className={classes.inputContainer}>
						<div className={classes.dateAndTimeSection}>
							<DateFilterInput
								id={incidentDateInput.name}
								label={incidentDateInput.label}
								value={getIncidentDate(incidentDateInput.value)}
								onChange={onSelectIncidentDateInput}
								hasError={errors.includes(incidentDateInput.name)}
								required={incidentDateInput.required}
								maxDate={new Date()}
								dateFormat="dd/MM/yyyy"
								disabled={isDateAndTimeNotKnownInput.value.toString() == 'true'}
							/>

							{/* <TextInput
								type="date"
								max={CustomDate.currentDate()}
								id={incidentDateInput.name}
								label={incidentDateInput.label}
								value={getIncidentDate(incidentDateInput.value)}
								onChange={onSelectIncidentDateInput}
								hasError={errors.includes(incidentDateInput.name)}
								required={incidentDateInput.required}
								ref={incidentDateInput.ref as RefObject<HTMLInputElement>}
								disabled={isDateAndTimeNotKnownInput.value.toString() == 'true'}
							/> */}

							<TextInput
								type="time"
								id={incidentTimeInput.name}
								label={incidentTimeInput.label}
								value={incidentTimeInput.value}
								onChange={onSelectIncidentTimeInput}
								hasError={errors.includes(incidentTimeInput.name)}
								required={incidentTimeInput.required}
								ref={incidentTimeInput.ref as RefObject<HTMLInputElement>}
								requiredIcon={false}
								disabled={isDateAndTimeNotKnownInput.value.toString() == 'true'}
							/>
						</div>
						<div className={classes.checkbox2Container}>
							<input
								onClick={(e: any) =>
									onSelectIsDateAndTimeNotKnownInput(e.target.checked)
								}
								value={isDateAndTimeNotKnownInput.value}
								className={classes.checkbox2}
								type="checkbox"
								name={isDateAndTimeNotKnownInput.name}
								id={isDateAndTimeNotKnownInput.labelKey}
							/>
							<label
								className={classes.checkbox2Label}
								htmlFor={isDateAndTimeNotKnownInput.labelKey}
							>
								{pageAssets[isDateAndTimeNotKnownInput.labelKey]}
							</label>
						</div>
					</div>

					{isDateAndTimeNotKnownInput.value.toString() == 'true' && (
						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={damageDiscoveryDateInput.name}
								label={damageDiscoveryDateInput.label}
								value={damageDiscoveryDateInput.value}
								onChange={onSelectDamageDiscoveryDateInput}
								hasError={errors.includes(damageDiscoveryDateInput.name)}
								required={damageDiscoveryDateInput.required}
								classWrapper={classes.inputContainer}
								maxDate={new Date()}
								dateFormat="dd/MM/yyyy"
							/>
							{/*
							<TextInput
								type="date"
								max={CustomDate.currentDate()}
								id={damageDiscoveryDateInput.name}
								label={damageDiscoveryDateInput.label}
								value={damageDiscoveryDateInput.value}
								onChange={onSelectDamageDiscoveryDateInput}
								hasError={errors.includes(damageDiscoveryDateInput.name)}
								required={damageDiscoveryDateInput.required}
								ref={
									damageDiscoveryDateInput.ref as RefObject<HTMLInputElement>
								}
								classWrapper={classes.inputContainer}
							/>*/}
						</div>
					)}

					<TextInput
						id={policeReportNumberInput.name}
						label={policeReportNumberInput.label}
						value={policeReportNumberInput.value}
						onChange={onSelectPoliceReportNumberInput}
						hasError={errors.includes(policeReportNumberInput.name)}
						required={policeReportNumberInput.required}
						ref={policeReportNumberInput.ref as RefObject<HTMLInputElement>}
						classWrapper={classes.inputContainer}
						maxCharacters={100}
					/>

					<TextInput
						id={request311NumberInput.name}
						label={request311NumberInput.label}
						value={request311NumberInput.value}
						onChange={onSelectRequest311NumberInput}
						hasError={errors.includes(request311NumberInput.name)}
						required={request311NumberInput.required}
						ref={request311NumberInput.ref as RefObject<HTMLInputElement>}
						classWrapper={classes.inputContainer}
						maxCharacters={20}
					/>

					<TextAreaInput
						id={damageCauseDescriptionInput.name}
						label={damageCauseDescriptionInput.label}
						value={damageCauseDescriptionInput.value}
						onChange={onSelectDamageCauseDescriptionInput}
						hasError={errors.includes(damageCauseDescriptionInput.name)}
						required={damageCauseDescriptionInput.required}
						ref={
							damageCauseDescriptionInput.ref as RefObject<HTMLTextAreaElement>
						}
						maxCharacters={MAX_DESCRIPTION_CHARACTERS}
						hasDescriptionLabel={`${MAX_DESCRIPTION_CHARACTERS} ${pageAssets?.label_maximum_character}`}
						descriptionAlignment="left"
						displayDescriptionTop
					/>

					<TextAreaInput
						id={damageDescriptionInput.name}
						label={damageDescriptionInput.label}
						value={damageDescriptionInput.value}
						onChange={onSelectDamageDescriptionInput}
						hasError={errors.includes(damageDescriptionInput.name)}
						required={damageDescriptionInput.required}
						ref={damageDescriptionInput.ref as RefObject<HTMLTextAreaElement>}
						maxCharacters={MAX_DESCRIPTION_CHARACTERS}
						hasDescriptionLabel={`${MAX_DESCRIPTION_CHARACTERS} ${pageAssets?.label_maximum_character}`}
						descriptionAlignment="left"
						displayDescriptionTop
					/>
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<RequestFiles
						subtitle={firstQuestion.label}
						name={firstQuestion.name}
						required={firstQuestion.required}
						files={firstQuestion.files}
						hasError={errors.includes(firstQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFirstQuestion}
						subSteps={subSteps}
						maxFiles={10}
						description={firstQuestion.description}
					>
						{/* <div
							className={joinClasses([
								classes.fileHelperSection,
								classes.fileContainer
							])}
						>
							<Text content={firstQuestion.description} />
							<BlueBulletsList labels={firstQuestionListDescription} text />
						</div> */}
					</RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default ClaimNoticeForm
